import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Spinner,
} from 'reactstrap';
import google from '../../assets/img/google.svg';
import { signUp, signUpGoogle } from '../../store/actions/authAction';

function Register(props) {
	const { loading } = useSelector(state => state.auth);
	const { googleLoading } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const [authLoading, setAuthLoading] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();

		let formData = {
			name: e.target[0].value,
			email: e.target[1].value,
			password: e.target[2].value,
		};

		dispatch(
			signUp(formData, () => {
				props.setIsRegister(prevState => !prevState);
				props.setIsLogin(prevState => !prevState);
			})
		);
	};

	const handlRegisterWithGoogle = () => {
		dispatch(
			signUpGoogle(() => {
				if (props.isRegister) {
					props.handleClose();
				}
			})
		);
	};
	return (
		<div>
			<Modal
				isOpen={props.isRegister}
				toggle={props.handleClose}
				className='authModal'
			>
				<ModalHeader
					className='text-site-dark border-0'
					toggle={props.handleClose}
				>
					{' '}
				</ModalHeader>{' '}
				<ModalBody className=' py-0'>
					{' '}
					<Row>
						<Col className='authModal__textSec' md='6'>
							<h2 className='text-site-primary font-EB font-weight-bold'>
								Join the Mandla community!
							</h2>
							<p className=''>It's completely free to join</p>
							<ul className='list-unstyled'>
								<li className='mb-3'>
									<span className='mr-2'>📕</span>Add new
									words and examples to the dictionary
								</li>
								<li className='mb-3'>
									<span className='mr-2'>📖</span>Suggest
									edits to existing words and examples
								</li>
								<li className='mb-3'>
									<span className='mr-2'>📭</span>Receive
									email updates on the status of your word and
									example submissions
								</li>
							</ul>
						</Col>
						<Col className='authModal__formSec' md='6'>
							<Form
								className='form-auth'
								onSubmit={e => {
									handleSubmit(e);
								}}
							>
								<FormGroup>
									<Label for='exampleEmail'>Full name</Label>
									<div className='position-relative '>
										<i class='fas fa-user position-absolute input-icon'></i>
										<Input
											id='exampleEmail'
											name='email'
											placeholder='Full name'
											type='text'
											required
										/>
									</div>
								</FormGroup>
								<FormGroup>
									<Label for='exampleEmail'>
										Email address
									</Label>
									<div className='position-relative '>
										<i class='fas fa-envelope position-absolute input-icon'></i>
										<Input
											id='exampleEmail'
											name='email'
											placeholder='Email address'
											type='email'
											required
										/>
									</div>
								</FormGroup>
								<FormGroup>
									<Label for='examplePassword'>
										Password
									</Label>
									<div className='position-relative '>
										<i class='fas fa-lock position-absolute input-icon'></i>
										<Input
											id='examplePassword'
											required
											name='password'
											placeholder='Password'
											type='password'
										/>
										{/* <p class='font-weight-bold text-dark position-absolute input-pasShow'>
											{' '}
											Show
										</p> */}
									</div>
								</FormGroup>
								<Button
									type='submit'
									color='site-primary'
									className='font-weight-bold w-100 py-2 my-3'
									disabled={loading}
								>
									{loading ? (
										<Spinner size='sm' />
									) : (
										'Sign Up'
									)}
								</Button>
								<Link
									className='text-dark text-decoration-none text-center d-block'
									onClick={() => {
										props.setIsRegister(
											prevState => !prevState
										);
										props.setIsLogin(
											prevState => !prevState
										);
									}}
								>
									Already on Mandla?
									<span className='font-weight-bold '>
										{' '}
										Login into account
									</span>
								</Link>{' '}
							</Form>

							<p className='text-site-muted my-2 font-weight-bold text-center d-block'>
								or
							</p>
							<Button
								color='light'
								className='w-100 my-2 py-3 d-flex'
								disabled={googleLoading}
								onClick={() => {
									handlRegisterWithGoogle();
								}}
							>
								<img src={google} className='ml-0' />
								<p className='text-dark mb-0 m-auto'>
									{' '}
									{googleLoading ? (
										<Spinner size='sm' />
									) : (
										'Sign in with Google'
									)}
								</p>
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default Register;
