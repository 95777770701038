import { FETCH_ALL_USERS } from '../types';

const initialState = {
	users: [],
};

export default function userReducer(state = initialState, { type, payload }) {
	switch (type) {
		case FETCH_ALL_USERS:
			return {
				...state,
				users: payload,
			};
		default:
			return {
				...state,
			};
	}
}
