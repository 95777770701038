// Root Reducer

import { combineReducers } from 'redux';
import wordReducer from './wordReducer';
import authUserReducer from './authReducer';
import authUser from './authUser';
import searchReducer from './searchReducer';
import languageReducer from './languageReducer';
import userReducer from './userReducer';
export let rootReducer = combineReducers({
	auth: authUserReducer,
	authUser: authUser,
	language: languageReducer,
	word: wordReducer,
	fetchWords: searchReducer,
	user: userReducer,
});

export default rootReducer;
