import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import Search from "../views/Search";
import VersionHistory from "../views/VersionHistory";
import Word from "../views/Word";

let routes = [
  {
    path: "/:lang/auth",
    component: AuthView,
    layout: "auth",
  },
  {
    path: "/Lang/:pageLanguage/:lang/:script",
    component: Home,
    layout: "main",
  },
  {
    path: "/Lang/:pageLanguage/:lang/:script/search",
    component: Search,
    layout: "main",
  },
  {
    path: "/Lang/:pageLanguage/:lang/:script/version/:id",
    component: VersionHistory,
    layout: "main",
  },
  {
    path: "/Lang/:pageLanguage/:lang/:script/word/:id",
    component: Word,
    layout: "main",
  },
];
export default routes;
