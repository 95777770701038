import { SET_FETCHED_WORDS } from '../types';

const initialState = {
	// wordLoading: false,
	fetchedWords: [],
};

export default function fetchedWordsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_FETCHED_WORDS:
			return {
				...state,
				fetchedWords: action.payload,
			};
		default:
			return {
				...state,
			};
	}
}
