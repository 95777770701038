import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import UpdateWordModel from "../components/modal/UpdateWordModel";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import image from "../assets/img/exampleIMG.png";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
  Card,
} from "reactstrap";
import { getWordAction } from "../store/actions/searchActions";
import { toast } from "react-toastify";
import {
  changeWordStatus,
  getAllVersions,
  getWordVersions,
} from "../store/actions/wordActions";
import { getAllUsers } from "../store/actions/userActions";
import useAudioPlayer from "./useAudioPlayer";
import WordCard from "../components/WordCard";
import { useTranslation } from "react-i18next";
import { checkScript } from "../components/checkScript";
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Search = () => {
  const history = useHistory();
  const params = useParams();

  // const [playing, setPlaying] = useState(false);
  // const [playingId, setPlayingId] = useState('');
  const [audioId, setaudioId] = useState("");
  const { playing, setPlaying } = useAudioPlayer(audioId);
  const [word, setWord] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const { uid, user } = useSelector((state) => state.authUser);
  const { users } = useSelector((state) => state.user);
  const [versionsModal, setVersionsModal] = useState(false);
  let { languages, script, pageLanguage } = useSelector(
    (state) => state.language
  );

  const { versionsLoading, versions, statusLoading } = useSelector(
    (state) => state.word
  );
  const [currentVersion, setCurrentVersion] = useState("");
  const [wordId, setWordId] = useState("");
  const [items, setItems] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [searched, setSearched] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const versionsToggle = () => {
    setVersionsModal(!versionsModal);
  };
  // console.log("versions",versions)
  const showDropdown = () => {
    document.getElementById("dropdown-menu").style.display = "block";
  };
  const hideMenu = () => {
    document.getElementById("dropdown-menu").style.display = "none";
  };
  // model operations
  let [updateWord, setUpdateWord] = useState(false);
  const handleCloseU = () => {
    setUpdateWord(false);
  };
  // model operations end

  // complete data about word
  const { fetchedWords } = useSelector((state) => state.fetchWords);
  const dispatch = useDispatch();
  const location = useLocation();
  const [result, setResult] = useState([]);
  const [resultData, setResultData] = useState({});
  const [singleItem, setSingleItem] = useState("");
  // let searchedData = [];
  // searchedData.push({ id: location.state.id, name: location.state.name });

  // useEffect(() => {
  // 	setResultData(fetchedWords?.find(item => item.id == location.state.id));
  // }, [fetchedWords]);
  // const params = useParams();

  const handleHistoryPage = (wordId) => {
    history.push({
      pathname: `/Lang/${params.pageLanguage}/${params.lang}/${params.script}/version/${wordId}`,
      state: wordId,
    });
  };
  const checkUserLanguage = (language) => {
    let languageExist = user.AdminLangs.find((langu) => langu == language);
    if (languageExist) {
      return true;
    } else {
      return false;
    }
  };
  // const [wordAudio, setWordAudio] = useState('');

  useEffect(() => {
    dispatch(getAllVersions(params.lang));
    if (users?.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);
  useEffect(() => {
    if (wordId != "") {
      dispatch(getWordVersions(wordId));
    }
  }, [wordId]);

  const getWordData = (id) => {
    if (fetchedWords?.length > 0) {
      let obj = fetchedWords?.find((item) => item.id == id);
      return obj;
    }
  };

  const query = useQuery();

  const getUserData = (id) => {
    if (users?.length > 0) {
      let obj = users.find((user) => user.uid == id);
      if (obj) {
        return obj;
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const getVersionsButton = (version) => {
    switch (version.status) {
      case "current":
        return (
          <Button
            className="text-white bg-success"
            size="sm"
            disabled={statusLoading}
          >
            Current
          </Button>
        );

      case "old":
      case "pending":
        return (
          <Button
            className="text-white bg-success"
            size="sm"
            disabled={statusLoading}
            onClick={() => {
              setCurrentVersion(version.id);
              dispatch(
                changeWordStatus(wordId, version, () => {
                  setCurrentVersion("");
                  versionsToggle();
                })
              );
            }}
          >
            {statusLoading && currentVersion == version.id ? (
              <Spinner size="sm" />
            ) : (
              <>{version.status == "old" ? "Restore" : "Need Approval"}</>
            )}
          </Button>
        );
      default:
        return;
    }
  };

  useEffect(() => {
    let __word = query.get("word");
    setWord(__word);
    if (location.state) {
      console.log("state=>", location.state);
      setSearchedData(location.state);
    }
  }, [location]);
  // console.log(location.state,"location state");
  // console.log(searchedData,"searched Data");
  let language;
  if (languages.length > 0) {
    language = languages.find((lang) => lang.id === params.lang);
  }
  const handleOnSearch = (value, results) => {
    setSearchInput(value);
    if (value == "") {
      let words = getLanguageWords();

      setSearchedData(words);
      history.push({
        pathname: `/Lang/${params.pageLanguage}/${params.lang}/${script}/search`,
      });
    } else {
      setSearchedData(results);
    }
  };
  // console.log('Searched input', searchInput);
  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    if (item) {
      setSingleItem(item.word);
      history.push({
        pathname: `/Lang/${params.pageLanguage}/${params.lang}/${script}/search`,
        search: `?word=${item.word}`,
        state: [item],
      });
    }
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    console.log("item=>", item);
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.word}</span>
      </>
    );
  };
  // search field operations

  const searchOnClick = () => {
    if (!searchInput) {
      history.push({
        pathname: `/Lang/${params.pageLanguage}/${params.lang}/${params.script}/search`,
        search: `?word=${searchInput}`,
        state: items,
      });
    } else {
      history.push({
        pathname: `/Lang/${params.pageLanguage}/${params.lang}/${params.script}/search`,
        search: `?word=${searchInput}`,
        state: searchedData,
      });
    }
  };
  const getLanguageWords = () => {
    let data = [];
    fetchedWords?.map((item) => {
      if (item.language == params?.lang) {
        data.push({
          id: item.id,
          name: item.word + item.englishDescription,
          word: item.word,
        });
      }
    });
    return data;
  };
  useEffect(() => {
    let data = getLanguageWords();
    setItems(data);
    if (!location.state) {
      console.log(data);
      setSearchedData(data);
    }
  }, [fetchedWords, params.lang]);

  const getSortedData = () => {
    let sortedData = searchedData.sort((a, b) =>
      a?.word?.toLowerCase() > b?.word?.toLowerCase() ? 1 : -1
    );
    console.log("Sorted data", sortedData);
    return sortedData;
  };

  console.log("Searched data", searchedData);

  let { t } = useTranslation();

  const translation = t("translation", { returnObjects: true });

  // console.log(items,"items");
  return (
    <>
      <Container>
        <Row></Row>
        <Row className="mt-5 text-center">
          {/* <Col md="4"></Col> */}
          <Col className="mt-5 language-name ">
            <h3
              style={{
                fontFamily: language.font,
                direction: language.direction,
              }}
            >
              {language?.nameNativeScript}
            </h3>
            <h3 style={{ direction: "RTL" }}>{language?.nko}</h3>
            <h3>{params.lang}</h3>
            {languages &&
              languages?.scripts?.length &&
              languages?.scripts?.map((language) => {
                // no data of scripts
                <h3>
                  Language Name in {language.script} is {language.name}{" "}
                </h3>;
              })}
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col md="12" className="">
            {/* <Form className='search-page-form d-lg-flex'>
							<InputGroup className='search-page-row  '>
								<Input placeholder='Searched word' />
								<InputGroupAddon addonType='append  search-icon-box'>
									<InputGroupText className='search-icon'>
										<i class='fas fa-search'></i>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
						</Form> */}
            <Form className="search-form d-lg-flex">
              <InputGroup className="search-form-row mr-2 mb-lg-0 mb-3">
                {/* <Input
								placeholder={`Search with ${getLanguage(
									params.lang
								)}`}
							/>
							<InputGroupAddon addonType='append  search-icon-box'>
								<InputGroupText className='search-icon'>
									<i class='fas fa-search'></i>
								</InputGroupText>
							</InputGroupAddon> */}

                <div className="App-header">
                  <div className="search-auto-complete-main">
                    <ReactSearchAutocomplete
                      // style={{zIndex:-999}}
                      inputSearchString={singleItem}
                      styling={{ zIndex: 4 }}
                      items={items}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                    />
                  </div>
                </div>
              </InputGroup>
              <Button
                size="large"
                className="py-1 px-5 search-btn d-flex align-items-center justify-content-center"
                onClick={searchOnClick}
                // tag={Link}
                // to={`/${params.lang}/search`}
              >
                {checkScript(translation, script)?.search}
              </Button>
            </Form>
          </Col>
          {/* <Col md='2' xs='5' className='text-center mx-auto'>
						<Button className='py-1 px-lg-3 px-auto search-btn mt-lg-0 mt-2 w-100'>
							Search
						</Button>
					</Col> */}
          {/* <Col sm='12' className='px-3 search-tag-title mt-2'>
						<h3>
							<span className='seache-tagline'>Words</span> -{' '}
							{searchedData.length} found for {word}
						</h3>
					</Col> */}
          <br />
        </Row>

        {/* Word Card  */}

        {/* <Row className="my-3 justify-content-between border-bottom">
        <Col md="3">
          <div className="d-flex justify-content-start">
            <div>
              <p className="top-text mb-1">id here</p>

              <p className="result-title-text mb-1">CI</p>

              <h3 className="result-heading">oke mmadu</h3>
            </div>
          </div>
          <p className="pb-1 mb-1">Noun</p>

          <div className="mb-3">
            <div>
              <Button
                className="result-button d-flex align-items-center mt-2 px-2 py-0"
                onClick={() => {
                  showDropdown();
                }}
              >
                <span className="">
                  <i class="fas fa-pen"></i>
                </span>
                <p className="mb-0 mx-2">Edit</p>
                <span>
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </Button>
              <div className="dropdown-section border py-2" id="dropdown-menu">
                <Button
                  className="dropdown-item  px-1"
                  onClick={() => {
                    hideMenu();
                  }}
                >
                  <span className="mx-1">
                    <i class="fas fa-edit"></i>
                  </span>
                  Edit Word
                </Button>
                
                <Button
                  className="dropdown-item  px-1"
                  onClick={() => {
                    hideMenu();
                  }}
                >
                  <span className="mx-1">
                    <i class="fas fa-book"></i>
                  </span>
                  Create an Example
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col md="5 " className="result-mid-section">
          <div>
            <p>
              <span className="mx-3">1.</span> a man of great reputation
            </p>
          </div>
        </Col>
        <Col md="3" className="px-0">
          <div className="result-bookmark pb-2">
            <Button className="star-button">
              <span>
                <i class="far fa-star"></i>
              </span>
            </Button>
            <Button className="details-button">
              <u> Details </u>{" "}
              <span className="mx-1">
                <i class="fas fa-clipboard"></i>
              </span>
            </Button>
          </div>
        </Col>
      </Row> */}
        <Row noGutters className="card-section mb-3">
          {searchedData?.length > 0 &&
            getSortedData().map((data) => {
              return (
                <>
                  <Col xs="12">
                    <WordCard data={data} />
                  </Col>
                  {(user?.isSuperAdmin ||
                    (user?.isAdmin && checkUserLanguage(params.lang))) && (
                    <Col xs="7">
                      <div
                        size="sm"
                        className="mt-2 version-history"
                        onClick={async () => {
                          if (uid) {
                            // setWordId(data.id);
                            // versionsToggle();
                            handleHistoryPage(data.id);
                          } else {
                            toast.warning(
                              "Login first to view versions history!"
                            );
                          }
                        }}
                      >
                        {/* {t("versionHistory")} */}
                        {checkScript(translation, script)?.versionHistory}
                        {/* Last Edited at:
                      {versions.length > 0 &&
                        moment
                          .unix(
                            versions.filter((version) => {
                              return (
                                version.wordId === data.id &&
                                version.status === "current"
                              );
                            })[0]?.updatedAt.seconds
                          )
                          .format("DD MMM YYYY HH:mm:ss")} */}
                      </div>
                      <div size="sm" className="mt-2 version-history">
                        {checkScript(translation, script)?.lastEdited}:
                        {versions.length > 0 &&
                          moment
                            .unix(
                              versions.filter((version) => {
                                return (
                                  version.wordId === data.id &&
                                  version.status === "current"
                                );
                              })[0]?.updatedAt.seconds
                            )
                            .format("DD MMM YYYY HH:mm:ss")}
                      </div>
                    </Col>
                  )}
                </>
                // <Col md='12' className='card p-2 m-2'>
                // 	<h5>
                // 		<span className='mx-2'>word:</span>{' '}
                // 		{getWordData(data.id)?.word}
                // 	</h5>
                // 	<p>
                // 		<span className='mx-2'>tifinagh:</span>{' '}
                // 		{getWordData(data.id)?.tifinagh}
                // 	</p>
                // 	<p>
                // 		<span className='mx-2'>phonetic:</span>{' '}
                // 		{getWordData(data.id)?.phonetic}
                // 	</p>
                // 	<p>
                // 		<span className='mx-2'>
                // 			partsOfSpeech:
                // 		</span>{' '}
                // 		{getWordData(data.id)?.partsOfSpeech}
                // 	</p>
                // 	<p>
                // 		<span className='mx-2'>nsibidi:</span>{' '}
                // 		{getWordData(data.id)?.nsibidi}
                // 	</p>

                // 	{getWordData(data.id)?.image != '' && (
                // 		<p>
                // 			<span className='mx-2'>Image:</span>
                // 			<img
                // 				src={
                // 					getWordData(data.id)?.image
                // 				}
                // 				style={{
                // 					width: '50%',
                // 				}}
                // 				fluid
                // 			/>
                // 		</p>
                // 	)}

                // 	{getWordData(data.id)?.audio != '' && (
                // 		<p>
                // 			{/* <span className='mx-2'>Audio:</span>{' '} */}
                // 			<audio
                // 				id='audio'
                // 				src={
                // 					getWordData(data.id)?.audio
                // 				}
                // 				// controls
                // 			/>
                // 			{playing ? (
                // 				<Pause
                // 					handleClick={() =>
                // 						setPlaying(false)
                // 					}
                // 				/>
                // 			) : (
                // 				<Play
                // 					handleClick={() =>
                // 						setPlaying(true)
                // 					}
                // 				/>
                // 			)}
                // 		</p>
                // 	)}
                // 	<p>
                // 		<span className='mx-2'>Language:</span>{' '}
                // 		{getWordData(data.id)?.language}
                // 	</p>
                // 	<p>
                // 		<span className='mx-2 mb-0'>
                // 			Example sectences:
                // 		</span>
                // 	</p>
                // 	{getWordData(
                // 		data.id
                // 	)?.exampleSentences?.map(item => {
                // 		return <p>{item?.exampleSentence}</p>;
                // 	})}
                // 	<p>
                // 		<span className='mx-2'>Notes:</span>{' '}
                // 		{getWordData(data.id)?.notes[0]}
                // 	</p>
                // 	<p>
                // 		<span className='mx-2'>
                // 			Time Posted:
                // 		</span>{' '}
                // 		{moment
                // 			.unix(
                // 				getWordData(data.id)?.timePosted
                // 					?.seconds
                // 			)
                // 			.format('DD-MM-YYYY')}
                // 	</p>
                // 	<Button
                // 		size='sm'
                // 		onClick={async () => {
                // 			if (uid) {
                // 				await setResultData(
                // 					getWordData(data.id)
                // 				);
                // 				setUpdateWord(
                // 					prevState => !prevState
                // 				);
                // 			} else {
                // 				toast.warning(
                // 					'Login first to update word!'
                // 				);
                // 			}
                // 		}}
                // 	>
                // 		Edit
                // 	</Button>

                // 	{(user?.isSuperAdmin ||
                // 		(user?.isAdmin &&
                // 			checkUserLanguage(lang))) && (
                // <Button
                // 	size='sm'
                // 	className='mt-2'
                // 	onClick={async () => {
                // 		if (uid) {
                // 			setWordId(data.id);
                // 			versionsToggle();
                // 		} else {
                // 			toast.warning(
                // 				'Login first to view versions history!'
                // 			);
                // 		}
                // 	}}
                // >
                // 	Version History
                // </Button>
                // 	)}
                // </Col>
              );
            })}
        </Row>
      </Container>
      <UpdateWordModel
        prevData={resultData}
        updateWord={updateWord}
        setUpdateWord={setUpdateWord}
        handleClose={() => handleCloseU()}
        currentLanguage={params.lang}
      />

      <Modal isOpen={versionsModal} toggle={versionsToggle}>
        <ModalHeader
          className="text-site-dark border-0"
          toggle={versionsToggle}
        >
          Versions History
        </ModalHeader>
        <ModalBody>
          {versionsLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner size="md" />
            </div>
          ) : (
            <>
              {versions?.length > 0 ? (
                versions.map((version) => {
                  return (
                    <Card className="mt-2 px-2">
                      <Row className="mt-1">
                        <Col md="6" className="d-flex flex-column">
                          <Label>
                            {/* Word:  */}
                            {version.word}
                          </Label>
                          {/* <Label>
														Tifinagh:{' '}
														{version.tifinagh}
													</Label>
													<Label>
														Phonetic:{' '}
														{version.phonetic}
													</Label> */}
                          <Label>
                            {/* Parts :  */}
                            {version.partsOfSpeech}
                          </Label>
                          {/* <Label>Example Setences:</Label> */}
                          {version.exampleSentences.map((sentence) => {
                            return <Label>{sentence.exampleSentence}</Label>;
                          })}

                          <Label>
                            {/* Notes:  */}
                            {version?.notes[0]}
                          </Label>
                        </Col>
                        <Col md="6" className="d-flex flex-column">
                          <Label>
                            Edited By: {getUserData(version.userId)?.name}
                          </Label>

                          {version.status && getVersionsButton(version)}
                        </Col>
                        <Col xs="12" className="d-flex">
                          Last edited:{" "}
                          {version.updatedAt &&
                            moment
                              .unix(version.updatedAt.seconds)
                              .format("DD MMM YYYY HH:mm:ss")}
                        </Col>
                      </Row>
                    </Card>
                  );
                })
              ) : (
                <h5>Versions history not available!</h5>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Search;
