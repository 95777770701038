import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Container, Label, Row, Spinner } from "reactstrap";
import {
  getAllVersions,
  getSingleWord,
  getSingleWordByLanguage,
} from "../store/actions/wordActions";
import useAudioPlayer from "./useAudioPlayer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import moment from "moment";
import { checkScript } from "../components/checkScript";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { EditNotifications } from "@mui/icons-material";
import UpdateWordModel from "../components/modal/UpdateWordModel";
import EditIcon from "@mui/icons-material/Edit";

const Word = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { word, getWordLoading, versions } = useSelector((state) => state.word);
  const { user, uid } = useSelector((state) => state.authUser);
  const [audioId, setaudioId] = useState("");
  const { playing, setPlaying } = useAudioPlayer(audioId);
  let { languages } = useSelector((state) => state.language);
  const [resultData, setResultData] = useState({});
  let [updateWord, setUpdateWord] = useState(false);
  const handleCloseU = () => {
    setUpdateWord(false);
  };

  const checkUserLanguage = (language) => {
    let languageExist = user.AdminLangs.find((langu) => langu == language);
    if (languageExist) {
      return true;
    } else {
      return false;
    }
  };
  let language;
  if (languages.length > 0) {
    language = languages.find((lang) => lang.id === params.lang);
  }
  let { t } = useTranslation();

  const translation = t("translation", { returnObjects: true });

  const handleHistoryPage = (wordId) => {
    history.push({
      pathname: `/Lang/${params.pageLanguage}/${params.lang}/${params.script}/version/${wordId}`,
      state: wordId,
    });
  };

  const getWordCode = () => {
    switch (params.pageLanguage.toLowerCase()) {
      case "english":
        return "word";
      case "spanish":
        return "es";
      case "french":
        return "fr";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getAllVersions(params.lang));
      dispatch(getSingleWordByLanguage(getWordCode(), params.id));
    }
  }, [params]);
  return (
    <Container>
      <Row className="mt-5">
        <Col xs="12" className="mt-5">
          {getWordLoading ? (
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            <>
              <Helmet>
                <title>
                  {`${
                    params.pageLanguage.toLowerCase() == "french"
                      ? word?.fr
                      : params.pageLanguage.toLowerCase() == "spanish"
                      ? word?.es
                      : word?.word
                  }`}
                </title>

                <meta
                  name="description"
                  content={`${
                    params.pageLanguage.toLowerCase() == "french"
                      ? word?.frenchDescription
                      : params.pageLanguage.toLowerCase() == "spanish"
                      ? word?.spanishDescription
                      : word?.englishDescription
                  }`}
                />
              </Helmet>

              {Object.keys(word).length == 0 ? (
                <h2 className="my-5 text-center">Word Not Found!</h2>
              ) : (
                <>
                  <Card className="word-card mt-5 px-md-4 px-0">
                    <Row noGutters className="mx-0">
                      <Col
                        sm="12"
                        md="6"
                        className="d-flex order-1 px-md-0 px-2 bordered"
                      >
                        <Row noGutters className="w-100 mx-0 d-flex  p-2">
                          <Col xs="6" className=" pr-">
                            <div className="order- d-flex flex-column py-2">
                              {/*params.script.toLowerCase() == "native" &&*/}
                              {word?.wordNativeScript && (
                                <Label
                                  style={{
                                    fontFamily: language.font,
                                    direction: language.direction,
                                  }}
                                >
                                  {word.wordNativeScript}
                                </Label>
                              )}

                              {/*params.script.toLowerCase() == "nko" &&*/}
                              {word?.wordNkoScript && (
                                <Label style={{}}>
                                  <div style={{ dir: "RTL" }}>
                                    {word.wordNkoScript}
                                  </div>
                                </Label>
                              )}

                              {params.pageLanguage.toLowerCase() ==
                                "english" && <Label>{word?.word} </Label>}
                              {params.pageLanguage.toLowerCase() ==
                                "french" && <Label>{word?.fr} </Label>}
                              {params.pageLanguage.toLowerCase() ==
                                "spanish" && <Label> {word?.es}</Label>}

                              <Col xs="6" className="pl-0">
                                {params.pageLanguage.toLowerCase() ==
                                  "english" &&
                                  word?.englishDescription && (
                                    <p style={{ fontSize: "15px" }}>
                                      {word.englishDescription}
                                    </p>
                                  )}
                                {params.pageLanguage.toLowerCase() ==
                                  "french" &&
                                  word?.frenchDescription && (
                                    <p style={{ fontSize: "15px" }}>
                                      {word.frenchDescription}
                                    </p>
                                  )}
                                {params.pageLanguage.toLowerCase() ==
                                  "spanish" &&
                                  word?.spanishDescription && (
                                    <p style={{ fontSize: "15px" }}>
                                      {word.spanishDescription}
                                    </p>
                                  )}
                              </Col>
                              {word?.dialect ? (
                                <div className="word-card__diaselect my-2">
                                  {word?.dialect}
                                </div>
                              ) : (
                                <div className="word-card__diaselect my-2">
                                  {"Standard"}
                                </div>
                              )}

                              {word?.partsOfSpeech && (
                                <p style={{ fontSize: "15px" }}>
                                  {word?.partsOfSpeech}
                                </p>
                              )}

                              {word?.phoneticPronunciation && (
                                <Label>{word.phoneticPronunciation}</Label>
                              )}

                              {word?.exampleSentences && (
                                  <p>
                                    <span className="mb-0">
                                      Example sentences:
                                    </span>
                                  </p>
                                ) &&
                                word?.exampleSentences?.map((item) => {
                                  if (item.exampleSentence != "") {
                                    return <p>{item?.exampleSentence}</p>;
                                  }
                                })}
                              {word?.notes && word?.notes[0] && (
                                <p>{word.notes[0]}</p>
                              )}
                            </div>
                          </Col>

                          {word?.image && word?.image != "" && (
                            <Col xs="6" className="d-flex pr-">
                              <img src={word?.image} className="w-100 m-auto" />
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                        className=" order-2 d-flex py-2 px-md-0 px-2 "
                      >
                        <Row noGutters className="w-100 mx-0">
                          <Col xs="10">
                            <div className="order- d-flex flex-column py-2 px-4 mr-auto">
                              {word?.nativeDefintion && (
                                <Label
                                  style={{
                                    fontFamily: language.font,
                                    direction: language.direction,
                                  }}
                                >
                                  {word.nativeDefintion}
                                </Label>
                              )}

                              {word?.etymology && (
                                <Label style={{}}>
                                  <div style={{ dir: "RTL" }}>
                                    {word.etymology}
                                  </div>
                                </Label>
                              )}

                              {word?.nativeDescriptionNativeScript && (
                                <Label style={{}}>
                                  <div style={{ dir: "RTL" }}>
                                    {word.nativeDescriptionNativeScript}
                                  </div>
                                </Label>
                              )}

                              {word?.nativeDescriptionNkoScript && (
                                <Label style={{}}>
                                  <div style={{ dir: "RTL" }}>
                                    {word.nativeDescriptionNkoScript}
                                  </div>
                                </Label>
                              )}
                              {word?.nativeDescriptionLatinScript && (
                                <Label style={{}}>
                                  <div style={{ dir: "RTL" }}>
                                    {word.nativeDescriptionLatinScript}
                                  </div>
                                </Label>
                              )}
                            </div>
                          </Col>
                          <Col xs="2" className="d-flex">
                            <div className="ml-auto d-flex">
                              <EditIcon
                                className="edit-icon ml-2"
                                onClick={async () => {
                                  if (uid) {
                                    await setResultData(word);
                                    setUpdateWord((prevState) => !prevState);
                                  } else {
                                    toast.warning(
                                      "Login first to update word!"
                                    );
                                  }
                                }}
                              ></EditIcon>
                              {/* <hr /> */}
                            </div>
                            <div className="">
                              <Label>
                                {word?.audio != "" && (
                                  <>
                                    {playing && audioId == word?.id ? (
                                      <div
                                        className="word-card__audio cursor-pointer"
                                        onClick={() => {
                                          setaudioId(word?.id);
                                          setPlaying(false);
                                        }}
                                      >
                                        <audio
                                          id={word?.id}
                                          src={word?.audio}
                                        />

                                        <i
                                          className="fa fa-pause"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    ) : (
                                      <div
                                        className="word-card__audio cursor-pointer"
                                        onClick={async () => {
                                          setaudioId(word?.id);
                                          setPlaying(true);
                                        }}
                                      >
                                        <VolumeUpIcon></VolumeUpIcon>

                                        <audio
                                          id={word?.id}
                                          src={word?.audio}
                                          // controls
                                        />
                                      </div>
                                    )}
                                  </>
                                )}{" "}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>

                  {(user?.isSuperAdmin ||
                    (user?.isAdmin && checkUserLanguage(params.lang))) && (
                    <Col xs="7">
                      <div
                        size="sm"
                        className="mt-2 version-history"
                        onClick={async () => {
                          if (uid) {
                            // setWordId(data.id);
                            // versionsToggle();
                            handleHistoryPage(word?.id);
                          } else {
                            toast.warning(
                              "Login first to view versions history!"
                            );
                          }
                        }}
                      >
                        {
                          checkScript(translation, params.script)
                            ?.versionHistory
                        }
                      </div>
                      <div size="sm" className="mt-2 version-history">
                        {checkScript(translation, params.script)?.lastEdited}:
                        {versions.length > 0 &&
                          moment
                            .unix(
                              versions.filter((version) => {
                                return (
                                  version.wordId === word?.id &&
                                  version.status === "current"
                                );
                              })[0]?.updatedAt.seconds
                            )
                            .format("DD MMM YYYY HH:mm:ss")}
                      </div>
                    </Col>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>

      <UpdateWordModel
        prevData={resultData}
        updateWord={updateWord}
        setUpdateWord={setUpdateWord}
        handleClose={() => handleCloseU()}
        currentLanguage={params.lang}
      />
    </Container>
  );
};

export default Word;
