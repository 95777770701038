import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Import CSS here
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/scss/custom.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store/index";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer position="top-center" />
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
