import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import Header from "../components/Header";
import { allLanguages } from "../config/allLanguages";
import { getUserData } from "../store/actions/authAction";
import {
  fetchLanguages,
  setLanguageScript,
  setPageLanguage,
} from "../store/actions/languageAction";
import { getWordAction } from "../store/actions/searchActions";
const Main = (props) => {
  let dispatch = useDispatch();
  let params = useParams();
  let [invalidLang, setInvalidLang] = useState(false);
  let [invalidScript, setInvalidScript] = useState(true);
  let [invalidSiteLang, setInvalidSiteLang] = useState(true);

  let { languages } = useSelector((state) => state.language);
  const { uid } = useSelector((state) => state.authUser);
  const { fetchedWords } = useSelector((state) => state.fetchWords);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function loadLanguages() {
      await dispatch(fetchLanguages());
    }
    loadLanguages();
    if (uid) {
      dispatch(getUserData(uid, () => {}));
    }
  }, []);

  useEffect(() => {
    if (languages != "pending") {
      let languageFound = languages.find(
        (language) => language.code == params.lang
      );
      if (languageFound == null) {
        setInvalidLang(true);
      }
    }
  }, [languages]);
  useEffect(() => {
    let validSiteLanguages = ["English", "Spanish", "French"];
    let validScripts = ["Latin", "Nko", "Native"];
    console.log(params.pageLanguage);
    if (
      validScripts.find(
        (validScrpt) =>
          validScrpt.toLocaleLowerCase() == params.script.toLocaleLowerCase()
      )
    )
      setInvalidScript(false);
    if (
      validSiteLanguages.find(
        (validLang) =>
          validLang.toLocaleLowerCase() == params.pageLanguage.toLowerCase()
      )
    )
      setInvalidSiteLang(false);
  }, []);

  const getLanguageCode = (name) => {
    let obj = allLanguages.find(
      (lang) => lang.name.toLowerCase() == name.toLowerCase()
    );
    if (obj) {
      return obj.code;
    } else {
      return "EN";
    }
  };

  let allScripts = ["Latin", "Native", "Nko"];
  console.log("all languages", allLanguages);

  const setUrlData = async () => {
    dispatch(getWordAction(params.lang));
    let newLang = await getLanguageCode(params.pageLanguage);
    console.log("New lang", newLang);
    dispatch(setPageLanguage(newLang));
    if (allScripts.includes(params.script)) {
      dispatch(setLanguageScript(params.script));
    } else {
      dispatch(setLanguageScript("Latin"));
    }
  };
  const getInvalidMessage = () => {
    let message = [];
    if (invalidSiteLang) message.push("Site Language");
    if (invalidLang) message.push("Language");
    if (invalidScript) message.push("Script");
    return message.join(", ");
  };

  useEffect(() => {
    let obj = allLanguages.find(
      (language) =>
        language.name.toLowerCase() == params.pageLanguage.toLocaleLowerCase()
    );
    if (invalidSiteLang == false) i18n.changeLanguage(obj.code.toUpperCase());
  }, [invalidSiteLang]);

  useEffect(() => {
    setUrlData();
  }, [params]);
  return (
    <>
      {languages == "pending" || fetchedWords == "loading" ? (
        <div className="full__screen">
          <div className="text-center">
            <Spinner />
            <div className="mt-2 ">
              <small className="font-weight-bold">Loading</small>
            </div>
          </div>
        </div>
      ) : languages.length == 0 ? (
        <div className="full__screen">
          <div className="text-center font-weight-bold">
            No Language Found!!!
          </div>
        </div>
      ) : invalidLang || invalidScript || invalidSiteLang ? (
        <div className="full__screen">
          <div className="text-center font-weight-bold">
            Invalid {getInvalidMessage()}
          </div>
        </div>
      ) : (
        <>
          <header>
            <Header />
          </header>
          {props.children}
        </>
      )}
      {/* <footer>Main Footer</footer> */}
    </>
  );
};

export default Main;
