import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import {
  translationEn,
  translationEs,
  translationFr,
} from "./config/i8nLanguages";

i18n.use(initReactI18next).init({
  resources: {
    EN: { translation: translationEn },
    ES: { translation: translationEs },
    FR: { translation: translationFr },
  },
  lng: localStorage.getItem("lang") || "EN",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18n;
