import { LOGIN, LOGOUT, SET_AUTH_LOADING, SET_GOOGLE_LOADING } from '../types';
import firebase from '../../config/firebase-config';
import { toast } from 'react-toastify';

export const setAuthLoading = val => async dispatch => {
	dispatch({ type: SET_AUTH_LOADING, payload: val });
};
export const setGoogleLoading = val => async dispatch => {
	dispatch({ type: SET_GOOGLE_LOADING, payload: val });
};

export const login =
	(loginData, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(setAuthLoading(true));

		firebase
			.auth()
			.signInWithEmailAndPassword(loginData.email, loginData.password)
			.then(async loginResponse => {
				let { user } = loginResponse;
				await dispatch(getUserData(user.uid, onSuccess));
			})
			.catch(err => {
				alert(err.message);
				dispatch(setAuthLoading(false));
			});
	};

export const logout = () => async dispatch => {
	localStorage.clear();
	dispatch({
		type: LOGOUT,
	});
};

export const signUp =
	(data, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(setAuthLoading(true));
		let email = data.email;
		let password = data.password;

		firebase
			.auth()
			.createUserWithEmailAndPassword(email, password)
			.then(addData => {
				delete data['password'];
				firebase
					.firestore()
					.collection('Users')
					.doc(addData.user.uid)
					.set({
						uid: addData.user.uid,
						provider: 'email',
						createdAt: firebase.firestore.Timestamp.now(),
						isAdmin: false,
						isSuperAdmin: false,
						EditedCount: 0,
						AdminLangs: [],
						...data,
					});
				dispatch(setAuthLoading(false));
				onSuccess();
			})
			.catch(err => {
				alert(err.message);
				dispatch(setAuthLoading(false));
			});
	};

export const signUpGoogle =
	(onSuccess = () => {}) =>
	async dispatch => {
		dispatch(setGoogleLoading(true));
		var provider = new firebase.auth.GoogleAuthProvider();
		let createdAt = firebase.firestore.Timestamp.now();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(async query => {
				await firebase
					.firestore()
					.collection('Users')
					.doc(query.user.uid)
					.onSnapshot(async userQuery => {
						let FreshUser = userQuery.data();
						if (!FreshUser) {
							firebase
								.firestore()
								.collection('Users')
								.doc(query.user.uid)
								.set({
									email: query.user.email,
									uid: query.user.uid,
									name:
										query.user.displayName.split(' ')[0] +
										' ' +
										query.user.displayName.split(' ')[1],
									isAdmin: false,
									isSuperAdmin: false,
									EditedCount: 0,
									AdminLangs: [],
									provider: 'google',
								})
								.then(() => {
									dispatch({
										type: LOGIN,
										payload: {
											email: query.user.email,
											uid: query.user.uid,
											name:
												query.user.displayName.split(
													' '
												)[0] +
												' ' +
												query.user.displayName.split(
													' '
												)[1],
											isAdmin: false,
											isSuperAdmin: false,
											EditedCount: 0,
											AdminLangs: [],
											provider: 'google',
										},
									});
									onSuccess();

									dispatch(setGoogleLoading(false));
								});
						} else {
							// add user data to redux
							dispatch({
								type: LOGIN,
								payload: FreshUser,
							});
							dispatch(setGoogleLoading(false));
							onSuccess();
						}
					});
			})
			.catch(error => {
				dispatch(setGoogleLoading(false));
			});
	};

export const getUserData = (id, onSuccess) => async dispatch => {
	firebase
		.firestore()
		.collection('Users')
		.doc(id)
		.onSnapshot(query => {
			if (query.data()) {
				dispatch({
					type: LOGIN,
					payload: { id, ...query.data() },
				});
				dispatch(setAuthLoading(false));
				onSuccess();
			} else {
				toast.warning('Invalid User!');
				dispatch(setAuthLoading(false));
			}
		});
};
