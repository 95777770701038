export function checkScript(translation, script) {
	// console.log("translation=>", translation);
	if (Array.isArray(translation)) {
		let obj = translation.find(
			trans => trans.script.toLowerCase() == script.toLowerCase()
		);
		// console.log("obj=>", obj);
		if (obj) {
			return obj;
		} else {
			if (translation.length > 0) return translation[0];
			else return;
		}
	}
	return;
}
