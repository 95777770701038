import {
	AUTH_NOTIFICATION,
	SET_AUTH_LOADING,
	SET_GOOGLE_LOADING,
	SET_ADD_WORD_LOADING,
	SET_VERSIONS_LOADING,
} from '../types';

const initialState = {
	loading: false,
	wordLoading: false,
	googleLoading: false,
	user: {},
	versionsLoading: false,
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AUTH_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_GOOGLE_LOADING:
			return {
				...state,
				googleLoading: action.payload,
			};
		case SET_ADD_WORD_LOADING:
			return {
				...state,
				wordLoading: action.payload,
			};
		case SET_VERSIONS_LOADING:
			return {
				...state,
				versionsLoading: action.payload,
			};
		default:
			return {
				...state,
			};
	}
}
