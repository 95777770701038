import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Container, Label, Row, Spinner } from "reactstrap";
// import { getWordVersions } from '../store/actions/wordActions';
import { getAllUsers } from "../store/actions/userActions";
import {
  changeWordStatus,
  getWordVersions,
} from "../store/actions/wordActions";
import moment from "moment";
import useAudioPlayer from "./useAudioPlayer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useTranslation } from "react-i18next";
import { checkScript } from "../components/checkScript";
const VersionHistory = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [currentVersion, setCurrentVersion] = useState("");
  const { users } = useSelector((state) => state.user);
  const { versionsLoading, versions, statusLoading } = useSelector(
    (state) => state.word
  );

  const { script, pageLanguage } = useSelector((state) => state.language);
  const [audioId, setaudioId] = useState("");
  const { playing, setPlaying } = useAudioPlayer(audioId);
  let { t } = useTranslation();

  const translation = t("translation", { returnObjects: true });
  useEffect(() => {
    if (params.id != "") {
      dispatch(getWordVersions(params.id));
    }
    if (users?.length == 0) {
      dispatch(getAllUsers());
    }
  }, [params.id]);
  const getUserData = (id) => {
    if (users?.length > 0) {
      let obj = users.find((user) => user.uid == id);
      if (obj) {
        return obj;
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const getVersionsButton = (version) => {
    switch (version.status) {
      case "current":
        return (
          <Button
            className="text-white bg-success"
            size="sm"
            disabled={statusLoading}
          >
            {checkScript(translation, script)?.current}
          </Button>
        );

      case "old":
      case "pending":
        return (
          <Button
            className="text-white bg-success"
            size="sm"
            disabled={statusLoading}
            onClick={() => {
              setCurrentVersion(version.id);
              dispatch(
                changeWordStatus(params.id, version, () => {
                  setCurrentVersion("");
                  // versionsToggle();
                })
              );
            }}
          >
            {statusLoading && currentVersion == version.id ? (
              <Spinner size="sm" />
            ) : (
              <>
                {version.status == checkScript(translation, script)?.old
                  ? checkScript(translation, script)?.restore
                  : checkScript(translation, script)?.needApproval}
              </>
            )}
          </Button>
        );
      default:
        return;
    }
  };
  console.log(params.id);
  return (
    // <><h1>VERSION HISTORY</h1></>
    <>
      <Container>
        {/* <Row className="mt-5"></Row> */}
        <Row className="mt-5"></Row>
        <div className=" mb-3 mt-5">
          {versionsLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner size="md" />
            </div>
          ) : (
            <>
              {versions?.length > 0 &&
                versions.map((version) => {
                  return (
                    <Col sm="12">
                      <Row>
                        <Col md="8" className="d-flex flex-column ">
                          <Card className="word-card mt-4 px-md-5 p-2 history-card">
                            <Row>
                              <Col md="6" className="d-flex flex-column">
                                <Label>
                                  {/* Word:  */}
                                  {version.word}
                                </Label>
                                {version?.dialect ? (
                                  <Button className="word-card__diaselect my-2">
                                    {version?.dialect}
                                  </Button>
                                ) : (
                                  <Button className="word-card__diaselect my-2">
                                    Standard
                                  </Button>
                                )}{" "}
                                {version.nativeDefintion != "" && (
                                  <Label>{version.nativeDefintion}</Label>
                                )}
                                {version.nativeScript != "" && (
                                  <Label>{version.nativeScript}</Label>
                                )}
                                {version.nativeDescriptionLatinScript != "" && (
                                  <Label>
                                    {version.nativeDescriptionLatinScript}
                                  </Label>
                                )}
                                {version.nativeDescriptionNkoScript != "" && (
                                  <Label>
                                    {version.nativeDescriptionNkoScript}
                                  </Label>
                                )}
                                {version.nativeDescriptionNativeScript !=
                                  "" && (
                                  <Label>
                                    {version.nativeDescriptionNativeScript}
                                  </Label>
                                )}
                                {version.partsOfSpeech != "" && (
                                  <Label>
                                    {/* Parts :{' '} */}
                                    {version.partsOfSpeech}
                                  </Label>
                                )}
                                {version.phoneticPronunciation != "" && (
                                  <Label>{version.phoneticPronunciation}</Label>
                                )}
                                {version.etymology != "" && (
                                  <Label>{version.etymology}</Label>
                                )}
                                {version.exampleSentences[0]?.exampleSentence !=
                                  "" &&
                                  version.exampleSentences?.map((sentence) => {
                                    return (
                                      <Label>{sentence.exampleSentence}</Label>
                                    );
                                  })}
                                {version?.notes.length > 0 && (
                                  <Label>{version?.notes[0]}</Label>
                                )}
                              </Col>
                              <Col className="d-flex flex-column">
                                <Label>
                                  {version?.audio != "" &&
                                    version?.audio != null && (
                                      <>
                                        {playing && audioId == version.id ? (
                                          <div
                                            className="word-card__audio cursor-pointer"
                                            onClick={() => {
                                              setaudioId(version.id);
                                              setPlaying(false);
                                            }}
                                          >
                                            <audio
                                              id={version.id}
                                              src={version.audio}
                                              // controls
                                            />
                                            {/* <VolumeUpIcon></VolumeUpIcon> */}
                                            <i
                                              className="fa fa-pause"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        ) : (
                                          <div
                                            className="word-card__audio cursor-pointer"
                                            onClick={async () => {
                                              setaudioId(version.id);
                                              setPlaying(true);
                                            }}
                                          >
                                            <VolumeUpIcon></VolumeUpIcon>
                                            {/* <i
                                              className="fa fa-play"
                                              aria-hidden="true"
                                            ></i> */}
                                            <audio
                                              id={version.id}
                                              src={version.audio}
                                              // controls
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}{" "}
                                </Label>
                                {version.wordLatinScript != "" && (
                                  <Label>{version.wordLatinScript}</Label>
                                )}
                                {version.wordNkoScript != "" && (
                                  <Label>{version.wordNkoScript}</Label>
                                )}
                                {version.wordNativeScript != "" && (
                                  <Label>{version.wordNativeScript}</Label>
                                )}
                                {pageLanguage.toLowerCase() == "en" && (
                                  <Label>{version.englishDescription} </Label>
                                )}
                                {pageLanguage.toLowerCase() == "es" && (
                                  <Label>{version.spanishDescription} </Label>
                                )}
                                {pageLanguage.toLowerCase() == "fr" && (
                                  <Label>{version.frenchDescription} </Label>
                                )}
                                {/* {version.englishDescription != "" && (
                                  <Label>{version.englishDescription}</Label>
                                )} */}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col
                          md="4"
                          className="d-flex flex-column word-card mt-4 px-md-5 p-2"
                        >
                          <Col md="7" className="d-flex flex-column">
                            <Label>
                              {checkScript(translation, script)?.editedBy} :{" "}
                              {getUserData(version.userId)?.email}
                            </Label>

                            {version.status && getVersionsButton(version)}
                          </Col>
                          <Col xs="12" className="d-flex">
                            {checkScript(translation, script)?.lastEdited}:{" "}
                            {version.updatedAt &&
                              moment
                                .unix(version.updatedAt.seconds)
                                .format("DD MMM YYYY HH:mm:ss")}
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
            </>
          )}
        </div>
      </Container>
    </>
  );
};
export default VersionHistory;
