const translationEn = {
	translation: [
		{
			search: 'Search',
			add: 'Add',
			update: 'Update',
			word: 'Word',
			script: 'Latin',
			search1: 'and learn',
			search2: 'words in different dialects',
			search3: 'for free',
			description: 'The Mandla ',
			description1:
				'Dictionary is the first multi lingual multi dialectal, multi scriptual audio-supported onlineparellel dictionary for',
			description2:
				'The crowd-sourced dictionary is completely free and the data is open-source. The Mandla dictionary features defintions in',
			description3:
				'in both native and latin scripts, as well as in English.',
			login: 'Login',
			register: 'Register',
			logout: 'Logout',
			versionHistory: 'Version History',

			partsOfSpeech: 'Part of Speech',
			image: 'Image',
			audio: 'Audio',
			dialect: 'Dialect',
			notes: 'Notes',
			exampleSentences: 'Example Sentences',
			etymology: 'Etymology',
			englishTranslation: 'English Translation',
			phoneticPronunciation: 'Phonetic Pronunciation',
			definition: 'Definition',
			in: 'in',
			scriptTrans: 'Script',
			review: 'Review',
			lastEdited: 'Last Edited',
			current: 'Current',
			restore: 'Restore',
			editedBy: 'Edited By',
			old: 'Old',
			needApproval: 'Need Approval',
		},
		{
			search: 'Search',
			add: 'Add',
			update: 'Update',
			word: 'Word',
			script: 'Nko',
			search1: 'and learn',
			search2: 'words in different dialects',
			search3: 'for free',

			description: 'The Mandla ',
			description1:
				'Dictionary is the first multi lingual multi dialectal, multi scriptual audio-supported onlineparellel dictionary for',
			description2:
				'The crowd-sourced dictionary is completely free and the data is open-source. The Mandla dictionary features defintions in',
			description3:
				'in both native and latin scripts, as well as in English.',
			login: 'Login',
			register: 'Register',
			logout: 'Logout',
			versionHistory: 'Version History',

			partsOfSpeech: 'Part of Speech',
			image: 'Image',
			audio: 'Audio',
			dialect: 'Dialect',
			notes: 'Notes',
			exampleSentences: 'Example Sentences',
			etymology: 'Etymology',
			englishTranslation: 'English Translation',
			phoneticPronunciation: 'Phonetic Pronunciation',
			definition: 'Definition',
			in: 'in',
			scriptTrans: 'Script',
			review: 'Review',
			lastEdited: 'Last Edited',
			current: 'Current',
			restore: 'Restore',
			editedBy: 'Edited By',
			old: 'Old',
			needApproval: 'Need Approval',
		},
		{
			search: 'Search',
			add: 'Add',
			update: 'Update',
			word: 'Word',
			script: 'Native',
			search1: 'and learn',
			search2: 'words in different dialects',
			search3: 'for free',
			description: 'The Mandla ',
			description1:
				'Dictionary is the first multi lingual multi dialectal, multi scriptual audio-supported onlineparellel dictionary for',
			description2:
				'The crowd-sourced dictionary is completely free and the data is open-source. The Mandla dictionary features defintions in',
			description3:
				'in both native and latin scripts, as well as in English.',
			login: 'Login',
			register: 'Register',
			logout: 'Logout',
			versionHistory: 'Version History',

			partsOfSpeech: 'Part of Speech',
			image: 'Image',
			audio: 'Audio',
			dialect: 'Dialect',
			notes: 'Notes',
			exampleSentences: 'Example Sentences',
			etymology: 'Etymology',
			englishTranslation: 'English Translation',
			phoneticPronunciation: 'Phonetic Pronunciation',
			definition: 'Definition',
			in: 'in',
			scriptTrans: 'Script',
			review: 'Review',
			lastEdited: 'Last Edited',
			current: 'Current',
			restore: 'Restore',
			editedBy: 'Edited By',
			old: 'Old',
			needApproval: 'Need Approval',
		},
	],
};
const translationEs = {
	translation: [
		{
			script: 'Latin',
			search: 'Búsqueda',
			update: 'Actualizar',
			add: 'Agregar',
			word: 'Palabra',
			search1: 'y aprender',
			search2: 'palabras en diferentes dialectos',
			search3: 'gratis',
			description: 'La mandla ',
			description1:
				'Dictionary es el primer diccionario paralelo en línea compatible con audio multilingüe, multidialectal y multiscriptual para',
			description2:
				'El diccionario de fuentes múltiples es completamente gratuito y los datos son de código abierto. El diccionario Mandla incluye definiciones en',
			description3:
				'tanto en escritura nativa como latina, así como en inglés.',
			login: 'Acceso',
			register: 'Registrarse',
			logout: 'Cerrar sesión',
			versionHistory: 'Historial de versiones',

			partsOfSpeech: 'Parte del discurso',
			image: 'Imagen',
			audio: 'Audio',
			dialect: 'Dialecto',
			notes: 'Notas',
			exampleSentences: 'oraciones de ejemplo',
			etymology: 'Etimología',
			englishTranslation: 'Traducción Española',
			phoneticPronunciation: 'Pronunciación fonética',
			definition: 'Definición',
			in: 'en',
			scriptTrans: 'Guion',
			review: 'Revisar',
			lastEdited: 'Última edición',
			current: 'Actual',
			restore: 'Restaurar',
			editedBy: 'Editado por',
			old: 'Antigua',
			needApproval: 'Necesita aprobacion',
		},
		{
			script: 'Nko',
			add: 'Agregar',
			update: 'Actualizar',
			word: 'Palabra',
			search: 'Búsqueda',
			search1: 'y aprender',
			search2: 'palabras en diferentes dialectos',
			search3: 'gratis',
			description: 'La mandla ',
			description1:
				'Dictionary es el primer diccionario paralelo en línea compatible con audio multilingüe, multidialectal y multiscriptual para',
			description2:
				'El diccionario de fuentes múltiples es completamente gratuito y los datos son de código abierto. El diccionario Mandla incluye definiciones en',
			description3:
				'tanto en escritura nativa como latina, así como en inglés.',
			login: 'Acceso',
			register: 'Registrarse',
			logout: 'Cerrar sesión',
			versionHistory: 'Historial de versiones',

			partsOfSpeech: 'Parte del discurso',
			image: 'Imagen',
			audio: 'Audio',
			dialect: 'Dialecto',
			notes: 'Notas',
			exampleSentences: 'oraciones de ejemplo',
			etymology: 'Etimología',
			englishTranslation: 'Traducción Española',
			phoneticPronunciation: 'Pronunciación fonética',
			definition: 'Definición',
			in: 'en',
			scriptTrans: 'Guion',
			review: 'Revisar',
			lastEdited: 'Última edición',
			current: 'Actual',
			restore: 'Restaurar',
			editedBy: 'Editado por',
			old: 'Antigua',
			needApproval: 'Necesita aprobacion',
		},
		{
			add: 'Agregar',
			word: 'Palabra',
			update: 'Actualizar',
			script: 'Native',
			search: 'Búsqueda',
			search1: 'y aprender',
			search2: 'palabras en diferentes dialectos',
			search3: 'gratis',
			description: 'La mandla ',
			description1:
				'Dictionary es el primer diccionario paralelo en línea compatible con audio multilingüe, multidialectal y multiscriptual para',
			description2:
				'El diccionario de fuentes múltiples es completamente gratuito y los datos son de código abierto. El diccionario Mandla incluye definiciones en',
			description3:
				'tanto en escritura nativa como latina, así como en inglés.',
			login: 'Acceso',
			register: 'Registrarse',
			logout: 'Cerrar sesión',
			versionHistory: 'Historial de versiones',

			partsOfSpeech: 'Parte del discurso',
			image: 'Imagen',
			audio: 'Audio',
			dialect: 'Dialecto',
			notes: 'Notas',
			exampleSentences: 'oraciones de ejemplo',
			etymology: 'Etimología',
			englishTranslation: 'Traducción Española',
			phoneticPronunciation: 'Pronunciación fonética',
			definition: 'Definición',
			in: 'en',
			scriptTrans: 'Guion',
			review: 'Revisar',
			lastEdited: 'Última edición',
			current: 'Actual',
			restore: 'Restaurar',
			editedBy: 'Editado por',
			old: 'Antigua',
			needApproval: 'Necesita aprobacion',
		},
	],
};
const translationFr = {
	translation: [
		{
			script: 'Latin',
			add: 'Ajouter',
			update: 'Mise à jour',
			word: 'Mot',
			search: 'Chercher',
			search1: 'et apprendre',
			search2: 'mots dans différents dialectes',
			search3: 'gratuitement',
			description: 'Le mandla ',
			description1:
				'Dictionary est le premier dictionnaire parallèle en ligne multilingue, multi-dialectique et multi-scriptuel pour',
			description2:
				'Le dictionnaire crowd-sourced est entièrement gratuit et les données sont open-source. Le dictionnaire Mandla propose des définitions dans',
			description3:
				"dans les écritures natives et latines, ainsi qu'en anglais.",
			login: 'Connexion',
			register: "S'inscrire",
			logout: 'Se déconnecter',
			versionHistory: 'Historique des versions',

			partsOfSpeech: 'Partie du discours',
			image: 'Image',
			audio: "l'audio",
			dialect: 'Dialecte',
			notes: 'Remarques',
			exampleSentences: 'Exemples de phrases',
			etymology: 'Étymologie',
			englishTranslation: 'Traduction française',
			phoneticPronunciation: 'Prononciation phonétique',
			definition: 'Définition',
			in: 'dans',
			scriptTrans: 'Scénario',
			review: 'Examen',
			lastEdited: 'Dernière modification',
			current: 'Courante',
			restore: 'Restaurer',
			editedBy: 'Édité par',
			old: 'Vieille',
			needApproval: "Besoin d'approbation",
		},
		{
			script: 'Nko',
			add: 'Ajouter',
			update: 'Mise à jour',
			word: 'Mot',
			search: 'Chercher',
			search1: 'et apprendre',
			search2: 'mots dans différents dialectes',
			search3: 'gratuitement',
			description: 'Le mandla ',
			description1:
				'Dictionary est le premier dictionnaire parallèle en ligne multilingue, multi-dialectique et multi-scriptuel pour',
			description2:
				'Le dictionnaire crowd-sourced est entièrement gratuit et les données sont open-source. Le dictionnaire Mandla propose des définitions dans',
			description3:
				"dans les écritures natives et latines, ainsi qu'en anglais.",
			login: 'Connexion',
			register: "S'inscrire",
			logout: 'Se déconnecter',
			versionHistory: 'Historique des versions',

			partsOfSpeech: 'Partie du discours',
			image: 'Image',
			audio: "l'audio",
			dialect: 'Dialecte',
			notes: 'Remarques',
			exampleSentences: 'Exemples de phrases',
			etymology: 'Étymologie',
			englishTranslation: 'Traduction française',
			phoneticPronunciation: 'Prononciation phonétique',
			definition: 'Définition',
			in: 'dans',
			scriptTrans: 'Scénario',
			review: 'Examen',
			lastEdited: 'Dernière modification',
			current: 'Courante',
			restore: 'Restaurer',
			editedBy: 'Édité par',
			old: 'Vieille',
			needApproval: "Besoin d'approbation",
		},
		{
			add: 'Ajouter',
			word: 'Mot',
			update: 'Mise à jour',
			script: 'Native',
			search: 'Chercher',
			search1: 'et apprendre',
			search2: 'mots dans différents dialectes',
			search3: 'gratuitement',
			description: 'Le mandla ',
			description1:
				'Dictionary est le premier dictionnaire parallèle en ligne multilingue, multi-dialectique et multi-scriptuel pour',
			description2:
				'Le dictionnaire crowd-sourced est entièrement gratuit et les données sont open-source. Le dictionnaire Mandla propose des définitions dans',
			description3:
				"dans les écritures natives et latines, ainsi qu'en anglais.",
			login: 'Connexion',
			register: "S'inscrire",
			logout: 'Se déconnecter',
			versionHistory: 'Historique des versions',

			partsOfSpeech: 'Partie du discours',
			image: 'Image',
			audio: "l'audio",
			dialect: 'Dialecte',
			notes: 'Remarques',
			exampleSentences: 'Exemples de phrases',
			etymology: 'Étymologie',
			englishTranslation: 'Traduction française',
			phoneticPronunciation: 'Prononciation phonétique',
			definition: 'Définition',
			in: 'dans',
			scriptTrans: 'Scénario',
			review: 'Examen',
			lastEdited: 'Dernière modification',
			current: 'Courante',
			restore: 'Restaurer',
			editedBy: 'Édité par',
			old: 'Vieille',
			needApproval: "Besoin d'approbation",
		},
	],
};

export { translationEn, translationEs, translationFr };
