import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Navbar,
	NavItem,
	NavbarToggler,
	Collapse,
	NavLink,
	Nav,
	NavbarBrand,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { logout } from '../store/actions/authAction';
import AuthModal from './modal/AuthModal';
import Register from './modal/Register';
import AddWordModel from './modal/AddWordModel';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import image from '../assets/img/1E1FFC8E-1151-448F-A8FD-E2FAEBB726B5.png';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

// import {
// 	addDialect,
// 	addTimeStamp,
// 	addWordsFromApi,
// } from '../store/actions/wordActions';
import LanguageContext from '../context/LanguageContext';
import {
	setLanguageScript,
	setPageLanguage,
} from '../store/actions/languageAction';
import { allLanguages } from '../config/allLanguages';
import { checkScript } from './checkScript';
const Header = () => {
	let params = useParams();
	const [collapsed, setCollapsed] = useState(true);
	const history = useHistory();

	const getLanguageName = code => {
		let obj = allLanguages.find(lang => lang.code == code);
		if (obj) {
			return obj.name;
		} else {
			return 'English';
		}
	};

	let context = useContext(LanguageContext);
	let [selectedLanguage, setSelectedLanguage] = useState(
		localStorage.getItem('lang') || 'EN'
	);
	let [isDropdownOpen, setIsDropdownOpen] = useState(false);
	let [isScriptOpen, setIsScriptOpen] = useState(false);

	const { t, i18n } = useTranslation();

	const { pageLanguage, script } = useSelector(state => state.language);
	let languages = ['EN', 'ES', 'FR'];
	let scripts = ['Latin', 'Nko', 'Native'];
	// useEffect(() => {
	//   context.updateLanguage(selectedLanguage);
	//   localStorage.setItem("lang", selectedLanguage);
	//   dispatch(setPageLanguage(selectedLanguage));
	// }, [selectedLanguage]);

	const translation = t('translation', { returnObjects: true });

	useEffect(() => {
		window.addEventListener('click', e => {
			let languageDropdowns =
				document.getElementsByClassName('language-dropdown');
			let languageDropdownMenus = document.getElementsByClassName(
				'language-dropdown-menu'
			);
			let isAny = false;
			for (let languageDropdown of Array.from(languageDropdowns)) {
				if (languageDropdown.contains(e.target)) {
					isAny = true;
				}
			}
			for (let languageDropdown of Array.from(languageDropdownMenus)) {
				if (languageDropdown.contains(e.target)) {
					isAny = true;
				}
			}
			if (!isAny) {
				setIsDropdownOpen(false);
			}
		});
	}, []);

	const toggleNavbar = () => setCollapsed(!collapsed);
	const [isOpen, setIsOpen] = useState(false);
	let [isLogin, setIsLogin] = useState(false);
	let [addWord, setAddWord] = useState(false);
	let [isRegister, setIsRegister] = useState(false);
	const dispatch = useDispatch();
	const { loading, uid } = useSelector(state => state.authUser);
	const handleLogout = id => {
		dispatch(logout(id));
	};

	const handleCloseL = () => {
		setIsLogin(false);
	};
	const handleCloseA = () => {
		setAddWord(false);
	};
	const handleCloseR = () => {
		// setIsLogin(false);
		setIsRegister(false);
	};

	// const handleAddClick = () => {
	// 	dispatch(addDialect());
	// };

	return (
		<>
			<div className='main-header px-lg-5 px-2 py-2'>
				<Navbar
					expand='sm'
					className='justify-content-center'
					color='faded'
					light
				>
					<NavbarBrand href='#home' tag={Link} to={`/${params.lang}`}>
						<img
							alt=''
							src={image}
							width='200'
							height='50'

							//   className="d-inline-block align-top"
						/>{' '}
					</NavbarBrand>
					<Nav
						className=' nav d-md-flex d-none align-items-center ml-lg-0 mr-auto'
						navbar
					>
						{/* <NavItem>
              <NavLink
                className="mx-lg-2 mx-1"
                tag={Link}
                to={`/${params.lang}`}
              >
                Home
              </NavLink>
            </NavItem> */}
						{/*Add Words From Api Button */}
						{/* <NavItem>
							<NavLink
								className='add-word mx-lg-2 mx-1 d-none d-sm-block ml-5'
								href='#'
								onClick={handleAddClick}
							>
								{' '}
								<span>
									<i class='fas fa-plus-circle'></i>
								</span>{' '}
								Add Word From Api
							</NavLink>
						</NavItem> */}
					</Nav>

					{/* <Nav className="ml-auto">
				<NavItem></NavItem>
			</Nav> */}
					<div className='d-flex row ml-auto'>
						<Nav className='ml-auto' navbar>
							{uid ? (
								<NavItem>
									<NavLink
										className='add-word mx-lg-2 mx-1 d-none d-sm-block ml-5'
										href='#'
										onClick={() => {
											if (uid) {
												setAddWord(
													prevState => !prevState
												);
											} else {
												toast.warning(
													'Login first to add new word!'
												);
											}
										}}
									>
										{' '}
										<span>
											<i class='fas fa-plus-circle'></i>
										</span>{' '}
										{
											checkScript(
												translation,
												params.script
											)?.add
										}{' '}
										{
											checkScript(
												translation,
												params.script
											)?.word
										}
									</NavLink>
								</NavItem>
							) : (
								''
							)}
						</Nav>
						{/* </Collapse> */}
						<div className='d-flex align-items-center header-buttons mr-3'>
							{uid ? (
								<Button
									color='site-danger'
									className='signout-btn d-none d-sm-block'
									size='md'
									onClick={() => {
										handleLogout(uid);
									}}
								>
									{
										checkScript(translation, params.script)
											?.logout
									}
								</Button>
							) : (
								<>
									<Button
										color='light'
										className='mx-2 text-site-primary lgn-btn  d-none d-sm-block nav-link-items'
										size='md'
										onClick={() => {
											setIsLogin(prevState => !prevState);
										}}
									>
										{
											checkScript(
												translation,
												params.script
											)?.login
										}
									</Button>
									<Button
										color='site-primary'
										className='reg-btn  d-none d-sm-block nav-link-items'
										size='md'
										onClick={() => {
											setIsRegister(
												prevState => !prevState
											);
										}}
									>
										{
											checkScript(
												translation,
												params.script
											)?.register
										}
									</Button>
								</>
							)}
						</div>

						<div className='position-relative ml-auto mt-2 d-none d-sm-block'>
							<div
								onClick={() => {
									setIsDropdownOpen(prevState => !prevState);
								}}
								className='d-flex align-items-center cursor-pointer no-select language-dropdown'
							>
								<span className='selected-language'>
									{selectedLanguage}
								</span>
								{isDropdownOpen ? (
									<i className='ml-1 fa fa-angle-up'></i>
								) : (
									<i className='ml-1 fa fa-angle-down'></i>
								)}
							</div>
							<div
								className={`position-absolute language-dropdown-menu ${
									isDropdownOpen ? 'd-block' : 'd-none'
								}`}
							>
								{languages.map(
									(language, languageIdx) =>
										context.lang != language && (
											<div
												key={
													'LanguageDropdown' +
													languageIdx
												}
												className='cursor-pointer no-select'
												onClick={() => {
													setSelectedLanguage(
														language
													);
													dispatch(
														setPageLanguage(
															language
														)
													);
													console.log(
														'language=>',
														language
													);
													i18n.changeLanguage(
														language
													);
													history.push(
														`/Lang/${getLanguageName(
															language
														)}/${params.lang}/${
															params.script
														}`
													);
													setIsDropdownOpen(false);
												}}
											>
												{language}
											</div>
										)
								)}
							</div>
						</div>

						<div className='position-relative pl-3 ml-auto mt-2 d-none d-sm-block'>
							<div
								onClick={() => {
									setIsScriptOpen(prevState => !prevState);
								}}
								className='d-flex align-items-center cursor-pointer no-select language-dropdown'
							>
								<span className='selected-language'>
									{script}
								</span>
								{isScriptOpen ? (
									<i className='ml-1 fa fa-angle-up'></i>
								) : (
									<i className='ml-1 fa fa-angle-down'></i>
								)}
							</div>

							<div
								className={`position-absolute language-dropdown-menu ${
									isScriptOpen ? 'd-block' : 'd-none'
								}`}
							>
								{scripts.map((scrpt, scriptIdx) => (
									<div
										key={'LanguageDropdown' + scriptIdx}
										className='cursor-pointer no-select'
										onClick={() => {
											dispatch(setLanguageScript(scrpt));

											history.push(
												`/Lang/${params.pageLanguage}/${params.lang}/${scrpt}`
											);
											setIsScriptOpen(false);
										}}
									>
										{scrpt}
									</div>
								))}
							</div>
						</div>
					</div>

					<NavbarToggler
						onClick={toggleNavbar}
						className='me-2 d-sm-none'
					/>
				</Navbar>
			</div>
			<div className='collapse-div main-header'>
				<Collapse isOpen={!collapsed} navbar className='d-sm-none'>
					<Navbar expand='md' className='navbar-collapse'>
						<Nav navbar>
							<Row>
								<Col>
									{uid ? (
										<NavItem>
											<NavLink
												className='mx-lg-2 mx-1 d-sm-none nav-link-items'
												style={{ fontWeight: 'bold' }}
												href='#'
												onClick={() => {
													if (uid) {
														setAddWord(
															prevState =>
																!prevState
														);
													} else {
														toast.warning(
															'Login first to add new word!'
														);
													}
												}}
											>
												{' '}
												{
													checkScript(
														translation,
														params.script
													)?.add
												}{' '}
												{
													checkScript(
														translation,
														params.script
													)?.word
												}{' '}
											</NavLink>
										</NavItem>
									) : (
										<NavItem>
											<NavLink
												className='mx-lg-2 mx-1 d-sm-none nav-link-items'
												style={{ fontWeight: 'bold' }}
												onClick={() => {
													setIsLogin(
														prevState => !prevState
													);
												}}
											>
												Login
											</NavLink>
										</NavItem>
									)}
									{uid ? (
										<NavItem className='d-sm-none'>
											<NavLink
												className='mx-lg-2 mx-1 d-sm-none nav-link-items'
												style={{ fontWeight: 'bold' }}
												onClick={() => {
													handleLogout(uid);
												}}
											>
												Logout
											</NavLink>
										</NavItem>
									) : (
										<NavItem>
											<NavLink
												className='mx-lg-2 mx-1 d-sm-none nav-link-items'
												style={{ fontWeight: 'bold' }}
												onClick={() => {
													setIsRegister(
														prevState => !prevState
													);
												}}
											>
												Register
											</NavLink>
										</NavItem>
									)}
								</Col>
								<Col className='d-flex justify-content-between mt-2'>
									<div className='position-relative'>
										<div
											onClick={() => {
												setIsDropdownOpen(
													prevState => !prevState
												);
											}}
											className='d-flex align-items-center cursor-pointer no-select language-dropdown'
										>
											<span className='selected-language'>
												{selectedLanguage}
											</span>
											{isDropdownOpen ? (
												<i className='ml-1 fa fa-angle-up'></i>
											) : (
												<i className='ml-1 fa fa-angle-down'></i>
											)}
										</div>
										<div
											className={`position-absolute language-dropdown-menu ${
												isDropdownOpen
													? 'd-block'
													: 'd-none'
											}`}
										>
											{languages.map(
												(language, languageIdx) =>
													context.lang !=
														language && (
														<div
															key={
																'LanguageDropdown' +
																languageIdx
															}
															className='cursor-pointer no-select'
															onClick={() => {
																setSelectedLanguage(
																	language
																);
																dispatch(
																	setPageLanguage(
																		language
																	)
																);
																console.log(
																	'language=>',
																	language
																);
																i18n.changeLanguage(
																	language
																);
																history.push(
																	`/Lang/${getLanguageName(
																		language
																	)}/${
																		params.lang
																	}/${
																		params.script
																	}`
																);
																setIsDropdownOpen(
																	false
																);
															}}
														>
															{language}
														</div>
													)
											)}
										</div>
									</div>

									<div className='position-relative ml-3'>
										<div
											onClick={() => {
												setIsScriptOpen(
													prevState => !prevState
												);
											}}
											className='d-flex align-items-center cursor-pointer no-select language-dropdown'
										>
											<span className='selected-language'>
												{script}
											</span>
											{isScriptOpen ? (
												<i className='ml-1 fa fa-angle-up'></i>
											) : (
												<i className='ml-1 fa fa-angle-down'></i>
											)}
										</div>

										<div
											className={`position-absolute language-dropdown-menu ${
												isScriptOpen
													? 'd-block'
													: 'd-none'
											}`}
										>
											{scripts.map((scrpt, scriptIdx) => (
												<div
													key={
														'LanguageDropdown' +
														scriptIdx
													}
													className='cursor-pointer no-select'
													onClick={() => {
														dispatch(
															setLanguageScript(
																scrpt
															)
														);

														history.push(
															`/Lang/${params.pageLanguage}/${params.lang}/${scrpt}`
														);
														setIsScriptOpen(false);
													}}
												>
													{scrpt}
												</div>
											))}
										</div>
									</div>
								</Col>
							</Row>
						</Nav>
					</Navbar>
				</Collapse>
			</div>
			{/*  </div> */}
			<AuthModal
				isLogin={isLogin}
				setIsLogin={setIsLogin}
				setIsRegister={setIsRegister}
				handleClose={() => handleCloseL()}
			/>
			<Register
				isRegister={isRegister}
				setIsLogin={setIsLogin}
				setIsRegister={setIsRegister}
				handleClose={() => handleCloseR()}
			/>
			<AddWordModel
				addWord={addWord}
				setAddWord={setAddWord}
				handleClose={() => handleCloseA()}
			/>
		</>
	);
};

export default Header;
