import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import routes from "./routes";
import store from "./store";
import "./App.css";

// import i18n from "i18next";
// import { initReactI18next, useTranslation } from "react-i18next";
// import {
//   translationEn,
//   translationEs,
//   translationFr,
// } from "./config/i8nLanguages";
import LanguageContext from "./context/LanguageContext";

import { getUserData } from "./store/actions/authAction";

// i18n.use(initReactI18next).init({
//   resources: {
//     EN: { translation: translationEn },
//     ES: { translation: translationEs },
//     FR: { translation: translationFr },
//   },
//   lng: localStorage.getItem("lang") || "EN",
//   fallbackLng: "en",
//   interpolation: { escapeValue: false },
// });

import { allLanguages } from "./config/allLanguages";
import { Helmet } from "react-helmet";

function App() {
  let [language, setLanguage] = useState(localStorage.getItem("lang") || "EN");
  let [script, setScript] = useState(localStorage.getItem("script") || "Latin");

  const getLanguageName = (code) => {
    let obj = allLanguages.find((lang) => lang.code == code);
    if (obj) {
      return obj.name;
    } else {
      return "English";
    }
  };

  // let { pageLanguage } = useSelector((state) => state.language);
  // let { t } = useTranslation();

  return (
    <>
      {/* <LanguageContext.Provider
        value={{
          lang: language,
          updateLanguage: (lang) => {
            setLanguage(lang);
            localStorage.setItem("lang", lang);
            i18n.changeLanguage(lang);
          },
        }}
      > */}

      <Helmet>
        <title>Mandla dictionary</title>
        <meta name="description" content="African dictionary" />
      </Helmet>

      <BrowserRouter>
        <Switch>
          {routes.map((route) => {
            switch (route.layout) {
              case "main":
                return (
                  <Route exact path={route.path}>
                    <Main>
                      <route.component />
                    </Main>
                  </Route>
                );
              case "auth":
                return (
                  <Route exact path={route.path}>
                    <Auth>
                      <route.component />
                    </Auth>
                  </Route>
                );
            }
          })}
          <Redirect to={`/Lang/${getLanguageName(language)}/Akan/${script}`} />
        </Switch>
      </BrowserRouter>
      {/* </LanguageContext.Provider> */}
    </>
  );
}

export default App;
