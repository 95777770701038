import { useState, useEffect } from "react";

function useAudioPlayer(id) {
  const [playing, setPlaying] = useState(false);
// console.log("id in cutosm hook",id)
  useEffect(() => {
    // console.log("Playing",playing)
    const audio = document.getElementById(id);
    playing ? audio?.play() : audio?.pause();
  });

  return {
    playing,
    setPlaying,
  }
}

export default useAudioPlayer;