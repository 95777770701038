import axios from "axios";
import firebase from "../../config/firebase-config";
import {
  SET_ADD_WORD_LOADING,
  FETCH_WORD_VERSIONS,
  SET_VERSIONS_LOADING,
  SET_STATUS_LOADING,
  GET_SINGLE_WORD,
  GET_WORD_LOADING,
  GET_SINGLE_WORD_BY_LANGUAGE,
} from "../types";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

export const setaddWordLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_ADD_WORD_LOADING, payload: val });
};

export const setVersionsLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_VERSIONS_LOADING, payload: val });
};
export const setStatusLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_STATUS_LOADING, payload: val });
};

let versionsCollection = firebase.firestore().collection("Versions");
let wordsCollection = firebase.firestore().collection("Words");
let usersCollection = firebase.firestore().collection("Users");

export const addWordAction =
  (addWordData, onSuccess = () => {}) =>
  async (dispatch) => {
    await dispatch(setaddWordLoading(true));
    let firebaseTimeStamp = firebase.firestore.Timestamp.now();
    let wordId = uuidv4();
    if (addWordData.image != "" || addWordData.audio != "") {
      if (addWordData.image != "") {
        if (addWordData.image.name) {
          let filename = addWordData.image.name;
          let ext2 = filename.slice(filename.lastIndexOf("."));
          let file_name = uuidv4() + ext2.toLowerCase();
          let storageRef = await firebase
            .storage()
            .ref(file_name)
            .put(addWordData.image);
          addWordData.image = await storageRef.ref.getDownloadURL();
        } else {
          addWordData.image = addWordData.image;
        }
      }
      if (addWordData.audio != "") {
        if (addWordData.audio.name) {
          let filename = addWordData.audio.name;
          let ext2 = filename.slice(filename.lastIndexOf("."));
          let file_name = uuidv4() + ext2.toLowerCase();
          let storageRef = await firebase
            .storage()
            .ref(file_name)
            .put(addWordData.audio);
          addWordData.audio = await storageRef.ref.getDownloadURL();
        } else {
          addWordData.audio = addWordData.audio;
        }
      }
    }
    await firebase
      .firestore()
      .collection("Words")
      .doc(wordId)
      .set({
        timePosted: firebaseTimeStamp,
        ...addWordData,
      })
      .then((res) => {
        firebase
          .firestore()
          .collection("Versions")
          .add({
            userId: addWordData.userId,
            isFirstCopy: true,
            status: "current",
            updatedAt: firebaseTimeStamp,
            wordId,
            ...addWordData,
          })
          .then((res) => {
            onSuccess();
            dispatch(setaddWordLoading(false));
            toast.success("Word added successfully!");
          })
          .catch((err) => {
            toast.error(err.message);
            dispatch(setaddWordLoading(false));
          });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setaddWordLoading(false));
      });
  };

export const incrementEditedCount = (user, onSuccess) => async (dispatch) => {
  firebase
    .firestore()
    .collection("Users")
    .doc(user.uid)
    .update({
      EditedCount: user.EditedCount + 1,
    })
    .then((res) => {
      onSuccess();
      toast.success("Word Updated Successfully");
      dispatch(setaddWordLoading(false));
    })
    .catch((err) => {
      toast.error(err.message);
      dispatch(setaddWordLoading(false));
    });
};

export const updateWordAction =
  (
    updateWordData,
    id,
    user,
    currentLanguage,
    isLangExist,
    onSuccess = () => {}
  ) =>
  async (dispatch) => {
    await dispatch(setaddWordLoading(true));

    let updatedAt = firebase.firestore.Timestamp.now();
    let wordData = await wordsCollection.doc(id).get();
    let versionsData = [];

    await versionsCollection
      .where("wordId", "==", id)
      .where("status", "==", "current")
      .get()
      .then((query) => {
        for (let doc of query.docs) {
          versionsData.push({ id: doc.id, ...doc.data() });
        }
      });

    if (updateWordData.image || updateWordData.audio) {
      if (updateWordData.image && updateWordData.image != "") {
        if (wordData.data().image != "") {
          await firebase.storage().refFromURL(wordData.data().image).delete();
        }
        let filename = updateWordData.image.name;
        let ext2 = filename.slice(filename.lastIndexOf("."));
        let file_name = uuidv4() + ext2.toLowerCase();
        let storageRef = await firebase
          .storage()
          .ref(file_name)
          .put(updateWordData.image);
        updateWordData.image = await storageRef.ref.getDownloadURL();
      }
      if (updateWordData.audio && updateWordData.audio != "") {
        // if (wordData.data().audio != '') {
        // 	await firebase
        // 		.storage()
        // 		.refFromURL(wordData.data().audio)
        // 		.delete();
        // }
        let filename = updateWordData.audio.name;
        let ext2 = filename.slice(filename.lastIndexOf("."));
        let file_name = uuidv4() + ext2.toLowerCase();
        let storageRef = await firebase
          .storage()
          .ref(file_name)
          .put(updateWordData.audio);
        updateWordData.audio = await storageRef.ref.getDownloadURL();
      }
    }

    if (user.isSuperAdmin) {
      versionsData.forEach((version) => {
        versionsCollection.doc(version.id).update({ status: "old" });
      });
      await versionsCollection.add({
        ...updateWordData,
        status: "current",
        updatedAt,
        wordId: id,
        userId: user.uid,
      });
      await wordsCollection
        .doc(id)
        .update(updateWordData)
        .then((res) => {
          dispatch(incrementEditedCount(user, onSuccess));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setaddWordLoading(false));
        });
    } else if (user.isAdmin && isLangExist) {
      versionsData.forEach((version) => {
        versionsCollection.doc(version.id).update({ status: "old" });
      });
      await versionsCollection.add({
        ...updateWordData,
        status: "current",
        updatedAt,
        isFirstCopy: false,
        wordId: id,
        userId: user.uid,
      });
      await wordsCollection
        .doc(id)
        .update(updateWordData)
        .then((res) => {
          dispatch(incrementEditedCount(user, onSuccess));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setaddWordLoading(false));
        });
    } else if (!user.isAdmin && user.EditedCount < 10) {
      versionsData.forEach((version) => {
        versionsCollection.doc(version.id).update({ status: "old" });
      });
      await versionsCollection.add({
        ...updateWordData,
        status: "current",
        updatedAt,
        wordId: id,
        userId: user.uid,
      });
      await wordsCollection
        .doc(id)
        .update(updateWordData)
        .then((res) => {
          dispatch(incrementEditedCount(user, onSuccess));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setaddWordLoading(false));
        });
    } else if (
      (!user.isAdmin && user.EditedCount >= 10) ||
      (user.isAdmin && !isLangExist && user.EditedCount >= 10)
    ) {
      await versionsCollection.add({
        ...updateWordData,
        userId: user.uid,
        status: "pending",
        updatedAt,
        wordId: id,
      });
      dispatch(incrementEditedCount(user, onSuccess));
    } else {
      toast.warning("Your limit reached out!");
      onSuccess();
    }

    // await wordsCollection
    // 	.doc(id)
    // 	.update(updateWordData)
    // 	.then(res => {
    // 		onSuccess();
    // 		toast.success('Word Updated Successfully');
    // 		dispatch(setaddWordLoading(false));
    // 	})
    // 	.catch(err => {
    // 		toast.error(err.message);
    // 		dispatch(setaddWordLoading(false));
    // 	});
  };

export const changeWordStatus =
  (wordId, wordData, onSuccess = () => {}) =>
  async (dispatch) => {
    let versionsData = [];
    await dispatch(setStatusLoading(true));

    let { id, updatedAt, userId, status, wordId, isFirstCopy, ...currentData } =
      wordData;
    await versionsCollection
      .where("wordId", "==", wordId)
      .where("status", "==", "current")
      .get()
      .then((query) => {
        for (let doc of query.docs) {
          versionsData.push({ id: doc.id, ...doc.data() });
        }
      });

    await versionsData.forEach((version) => {
      versionsCollection.doc(version.id).update({ status: "old" });
    });
    versionsCollection
      .doc(id)
      .update({ status: "current" })
      .then((err) => {
        wordsCollection
          .doc(wordId)
          .update(currentData)
          .then((res) => {
            onSuccess();
            dispatch(setStatusLoading(false));
            toast.success("Status changed successfully!");
          })
          .catch((err) => {
            toast.error(err.message);
            dispatch(setStatusLoading(false));
          });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setStatusLoading(false));
      });
  };

export const getWordVersions = (id) => async (dispatch) => {
  await dispatch(setVersionsLoading(true));
  versionsCollection
    .where("wordId", "==", id)
    .orderBy("updatedAt", "desc")
    .onSnapshot(async (query) => {
      let versionsData = [];
      for (let doc of query.docs) {
        versionsData.push({ id: doc.id, ...doc.data() });
      }
      dispatch({ type: FETCH_WORD_VERSIONS, payload: versionsData });
      dispatch(setVersionsLoading(false));
    });
};

export const getAllVersions = (lang) => async (dispatch) => {
  // await dispatch(setVersionsLoading(true));
  versionsCollection
    .where("language", "==", lang)
    .orderBy("updatedAt", "desc")
    .onSnapshot(async (query) => {
      let versionsData = [];
      for (let doc of query.docs) {
        versionsData.push({ id: doc.id, ...doc.data() });
      }
      dispatch({ type: FETCH_WORD_VERSIONS, payload: versionsData });
      dispatch(setVersionsLoading(false));
    });
};

//Add Words From Apis
export const addWordsFromApi = (uid) => async (dispatch) => {
  const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
  await axios.get(url + "/languages").then(async (res) => {
    for (let language of res.data) {
      await axios.get(url + "/courses/" + language.id).then(async (res) => {
        for (let course of res.data) {
          loadWords(course.id, uid, course.availWeb, dispatch);
        }
      });
    }
  });
};

//Load Words From Api
let loadWords = async (id, userId, availWeb, dispatch) => {
  const url = "https://us-central1-mandla-demo.cloudfunctions.net/api";
  await axios.get(url + "/course" + "/" + id + "/").then(async (res) => {
    let response = await firebase.firestore().collection("Words").get();
    var wordsData = [];
    for (let doc of response.docs) {
      wordsData.push({ id: doc.id, ...doc.data() });
    }
    let course = { ...res.data, availWeb: availWeb };
    let x = 0;
    while (x < course?.words?.length) {
      let obj = wordsData.find((val) => val.word == course.words[x].word);
      if (!obj) {
        if (
          course.words[x].image &&
          course.teaching != "Template" &&
          course.availWeb
        ) {
          let currentWord = course;
          if (currentWord.teaching == "Twi") {
            currentWord.teaching = "Akan";
            currentWord.words[x].dialect = "Asante Twi";
          }
          if (currentWord.teaching == "Kassem") {
            currentWord.teaching = "Gurunsi";
            currentWord.words[x].dialect = "Kassem";
          }
          if (currentWord.teaching == "Kabyle") {
            currentWord.teaching = "Tamazight";
            currentWord.words[x].dialect = "Kabyle";
          }
          if (currentWord.teaching == "Bambara") {
            currentWord.teaching = "Mandé";
            currentWord.words[x].dialect = "Bambara";
          }
          if (currentWord.teaching == "Mandinka") {
            currentWord.teaching = "Mandé";
            currentWord.words[x].dialect = "Mandinka";
          }
          if (currentWord.teaching == "Swahili") {
            currentWord.teaching = "KiSwahili";
          }
          if (currentWord.teaching == "Yoruba") {
            currentWord.teaching = "Yorùbá";
          }
          if (currentWord.teaching == "Zulu") {
            currentWord.teaching = "isiZulu";
          }
          if (currentWord.teaching == "Xhosa") {
            currentWord.teaching = "isiXhosa";
          }
          if (currentWord.teaching == "Oromo") {
            currentWord.teaching = "Afaan Oromoo";
          }

          let word = {
            wordLatinScript: course.words[x].word ? course.words[x].word : "",
            wordNkoScript: "",
            nko: "",
            wordNativeScript: "",
            englishDescription: course.words[x].description
              ? course.words[x].description
              : "",
            image: course.words[x].image ? course.words[x].image : "",
            nativeDescriptionLatinScript: "",
            nativeDescriptionNkoScript: "",
            nativeDescriptionNativeScript: "",
            partOfSpeech: "",
            dialect: course.words[x].dialect ? course.words[x].dialect : "",
            audio: course.words[x].audio ? course.words[x].audio : "",
            nativeScript: "",
            language: course.teaching ? course.teaching : "",
            notes: [],
            phonetic: "",
            nsibidi: "",
            partsOfSpeech: "",
            tifinagh: "",
            phoneticPronunciation: "",
            userId: userId,
            etymology: "",
            exampleSentences: [],
            // timePosted: '',
            source: "Mandla API",
            word: course.words[x].word ? course.words[x].word : "",
            object: course.words[x] ? course.words[x] : {},
          };
          dispatch(addWordAction(word));
        }
      }
      x++;
    }
  });
};

export const getSingleWord = (id) => async (dispatch) => {
  dispatch({ type: GET_WORD_LOADING, payload: true });
  firebase
    .firestore()
    .collection("Words")
    .doc(id)
    .onSnapshot(async (doc) => {
      if (doc.data()) {
        dispatch({ type: GET_SINGLE_WORD, payload: { ...doc.data(), id } });
      }
      dispatch({ type: GET_WORD_LOADING, payload: false });
    });
};

export const getSingleWordByLanguage = (key, val) => async (dispatch) => {
  dispatch({ type: GET_WORD_LOADING, payload: true });
  firebase
    .firestore()
    .collection("Words")
    .where(key, "==", val)
    .onSnapshot(async (query) => {
      let wordData = [];
      query.docs.forEach((doc) => {
        wordData.push({ id: doc.id, ...doc.data() });
      });
      if (wordData.length > 0) {
        dispatch({ type: GET_SINGLE_WORD_BY_LANGUAGE, payload: wordData[0] });
      } else {
        dispatch({ type: GET_SINGLE_WORD_BY_LANGUAGE, payload: {} });
      }

      // if (doc.data()) {
      //   dispatch({ type: GET_SINGLE_WORD, payload: { ...doc.data(), id } });
      // }
      dispatch({ type: GET_WORD_LOADING, payload: false });
    });
};
