export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SIGNUP = "SIGNUP";
export const SET_GOOGLE_LOADING = "SET_GOOGLE_LOADING";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_ADD_WORD_LOADING = "SET_ADD_WORD_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const SET_FETCHED_WORDS = "SET_FETCHED_WORDS";
export const UPDATE_WORD = "UPDATE_WORD";

export const FETCH_LANGUAGES = "FETCH_LANGUAGES";

export const FETCH_WORD_VERSIONS = "FETCH_WORD_VERSIONS";
export const SET_VERSIONS_LOADING = "SET_VERSIONS_LOADING";
export const SET_STATUS_LOADING = "SET_STATUS_LOADING";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";

export const GET_SINGLE_WORD = "GET_SINGLE_WORD";
export const GET_SINGLE_WORD_BY_LANGUAGE = "GET_SINGLE_WORD_BY_LANGUAGE";

export const GET_WORD_LOADING = "GET_WORD_LOADING";
