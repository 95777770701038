import firebase from "../../config/firebase-config";
import { FETCH_LANGUAGES } from "../types";

let Languages = firebase.firestore().collection("Languages");

export const fetchLanguages = () => async (dispatch) => {
  Languages.onSnapshot((snapshot) => {
    let languages = [];
    for (let doc of snapshot.docs) {
      languages.push({
        id: doc.id,
        code: doc.id,
        name: doc.data().name,
        dialects: doc.data().dialects,
        nko: doc.data().nko,
        hasNativeScript: doc.data().hasNativeScript,
        nativeScriptName: doc.data().nativeScriptName,
        nameNativeScript: doc.data().nameNativeScript,
        font: doc.data().font,
        direction: doc.data().direction,
      });
    }
    dispatch({
      type: FETCH_LANGUAGES,
      payload: languages,
    });
  });
};

export const setPageLanguage = (val) => async (dispatch) => {
  dispatch({ type: "SET_PAGE_LANGUAGE", payload: val });
};

export const setLanguageScript = (val) => async (dispatch) => {
  dispatch({ type: "SET_LANGUAGE_SCRIPT", payload: val });
};
