import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getWordAction } from "../../store/actions/searchActions";
import { useDispatch } from "react-redux";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { checkScript } from "../checkScript";
import { Helmet } from "react-helmet";

const HeroSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  let { t } = useTranslation();

  let [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
  let { languages, script } = useSelector((state) => state.language);
  let params = useParams();
  const getLanguage = (language) => {
    let languageFound = languages.find(
      (_language) => _language.code == language
    );
    let languageName = languageFound ? languageFound.name : "N/A";
    return languageName;
  };
  const handleClick = (event) => {
    let languageSelector = document.getElementById("language-selector");
    if (languageSelector && !languageSelector.contains(event.target)) {
      setIsLanguagesOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const [items, setItems] = useState([]);
  const { fetchedWords } = useSelector((state) => state.fetchWords);

  const [searchedData, setSearchedData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleOnSearch = (value, results) => {
    setSearchInput(value);
    setSearchedData(results);
  };
  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    history.push({
      pathname: `/Lang/${params.pageLanguage}/${params.lang}/${script}/search`,
      search: `?word=${item.name}`,
      state: [item],
    });
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  console.log("Language", params);
  // search field operations

  const searchOnClick = () => {
    if (!searchInput) {
      history.push({
        pathname: `/Lang/${params.pageLanguage}/${params.lang}/${script}/search`,
        search: `?word=${searchInput}`,
        state: items,
      });
    } else {
      history.push({
        pathname: `/Lang/${params.pageLanguage}/${params.lang}/${script}/search`,
        search: `?word=${searchInput}`,
        state: searchedData,
      });
    }
  };

  const { pageLanguage } = useSelector((state) => state.language);

  const translation = t("translation", { returnObjects: true });

  // const checkScript = () => {
  //   let obj = translation.find((trans) => trans.script == script);
  //   if (obj) {
  //     return obj;
  //   } else {
  //     if (translation.length > 0) return translation[0];
  //     else return;
  //   }
  // };

  useEffect(() => {
    let data = [];
    fetchedWords?.map((item) => {
      if (item.language == params?.lang) {
        data.push({
          id: item.id,
          name:
            pageLanguage.toLowerCase() == "es"
              ? item.es
              : pageLanguage.toLowerCase() == "fr"
              ? item.fr
              : item.word,
        });
      }
    });
    setItems(data);
  }, [fetchedWords]);

  return (
    <Container className="hero-section pt-5" fluid>
      <Helmet>
        <title>Mandla {getLanguage(params.lang)} dictionary</title>
        <meta
          name="description"
          content={`${checkScript(translation, script)?.search}
          ${checkScript(translation, script)?.search1} ${
            checkScript(translation, script)?.search2
          }
            ${checkScript(translation, script)?.search3}
          `}
          // content={`${checkScript(translation, script)?.description}{" "}
          //   ${getLanguage(params.lang)}{" "}
          //   ${checkScript(translation, script)?.description1}{" "}
          //   ${getLanguage(params.lang)}.{" "}
          //   ${checkScript(translation, script)?.description2}{" "}
          //   ${getLanguage(params.lang)},{" "}
          //   ${checkScript(translation, script)?.description3}`}
        />
      </Helmet>
      <Row className="hero-text-section mx-auto px-lg-2 px-3 py-2">
        <Col sm="12" className="hero-text-bold ">
          <h1>
            {" "}
            <u>{checkScript(translation, script)?.search}</u>{" "}
            {checkScript(translation, script)?.search1}{" "}
            <span
              className="lgbo position-relative"
              id="language-selector"
              onClick={() => setIsLanguagesOpen((prevState) => !prevState)}
            >
              {getLanguage(params.lang)}{" "}
              {isLanguagesOpen ? (
                <i class="fas fa-angle-up"></i>
              ) : (
                <i class="fas fa-angle-down"></i>
              )}
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                }}
                className={`position-absolute bg-white language__dropdown ${
                  isLanguagesOpen ? "d-block" : "d-none"
                }`}
              >
                {languages != "pending" &&
                  languages.map((language) => (
                    <Link
                      to={`/Lang/${params.pageLanguage}/${language.code}/${params.script}`}
                      className="p-2 language"
                    >
                      {language.name}
                    </Link>
                  ))}
              </div>
            </span>{" "}
            {checkScript(translation, script)?.search2}{" "}
            <span className="free">
              {checkScript(translation, script)?.search3}
            </span>
          </h1>
        </Col>
        <Col sm="12" className="pera-text">
          <p>
            {checkScript(translation, script)?.description}{" "}
            {getLanguage(params.lang)}{" "}
            {checkScript(translation, script)?.description1}{" "}
            {getLanguage(params.lang)}.{" "}
            {checkScript(translation, script)?.description2}{" "}
            {getLanguage(params.lang)},{" "}
            {checkScript(translation, script)?.description3}
          </p>
        </Col>
        <Col className="my-3">
          <Form className="search-form d-lg-flex">
            <InputGroup className="search-form-row mr-2 mb-lg-0 mb-3">
              {/* <Input
								placeholder={`Search with ${getLanguage(
									params.lang
								)}`}
							/>
							<InputGroupAddon addonType='append  search-icon-box'>
								<InputGroupText className='search-icon'>
									<i class='fas fa-search'></i>
								</InputGroupText>
							</InputGroupAddon> */}

              <div className="App-header">
                <div className="search-auto-complete-main">
                  <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                    formatResult={formatResult}
                  />
                </div>
              </div>
            </InputGroup>
            <Button
              size="large"
              className="py-1 px-5 search-btn d-flex align-items-center justify-content-center"
              onClick={searchOnClick}
              // tag={Link}
              // to={`/${params.lang}/search`}
            >
              {checkScript(translation, script)?.search}
              {/* Search */}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
