import firebase from '../../config/firebase-config';
import { FETCH_ALL_USERS } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

export const getAllUsers = () => async dispatch => {
	firebase
		.firestore()
		.collection('Users')
		.onSnapshot(async query => {
			let usersData = [];
			for (let doc of query.docs) {
				usersData.push({ ...doc.data() });
			}
			dispatch({ type: FETCH_ALL_USERS, payload: usersData });
		});
};
