import firebase from 'firebase';
import 'firebase/auth';
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCu5RyjzRt2s-exgxqdppgxAXgB7-CGbvQ',
	authDomain: 'dictionary-e1645.firebaseapp.com',
	projectId: 'dictionary-e1645',
	storageBucket: 'dictionary-e1645.appspot.com',
	messagingSenderId: '865628026943',
	appId: '1:865628026943:web:5fb9c27cc2a76734e6208a',
	measurementId: 'G-VV066H602R',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
