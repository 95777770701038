import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAudioPlayer from "../views/useAudioPlayer";
import { useLocation, useHistory, useParams } from "react-router-dom";
import UpdateWordModel from "../components/modal/UpdateWordModel";

import { Card, Col, Label, Row } from "reactstrap";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import image from "../assets/img/exampleIMG.png";
import { toast } from "react-toastify";

const WordCard = (props) => {
  const history = useHistory();
  const params = useParams();

  const { fetchedWords } = useSelector((state) => state.fetchWords);
  const { uid, user } = useSelector((state) => state.authUser);
  const { users } = useSelector((state) => state.user);
  const [resultData, setResultData] = useState({});
  const [audioId, setaudioId] = useState("");

  const { playing, setPlaying } = useAudioPlayer(audioId);

  let { languages } = useSelector((state) => state.language);
  let [updateWord, setUpdateWord] = useState(false);
  const handleCloseU = () => {
    setUpdateWord(false);
  };
  const handleHistoryPage = (wordId) => {
    history.push({
      pathname: `/${params.lang}/version/${wordId}`,
      state: wordId,
    });
  };
  const { lang, pageLanguage, script } = useParams();

  const checkUserLanguage = (language) => {
    let languageExist = user.AdminLangs.find((langu) => langu == language);
    if (languageExist) {
      return true;
    } else {
      return false;
    }
  };
  let language;
  if (languages.length > 0) {
    language = languages.find((lang) => lang.id === params.lang);
  }
  const getWordData = (id) => {
    if (fetchedWords?.length > 0) {
      let obj = fetchedWords?.find((item) => item.id == id);
      return obj;
    }
  };
  const getUserData = (id) => {
    if (users?.length > 0) {
      let obj = users.find((user) => user.uid == id);
      if (obj) {
        return obj;
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const getWordByPageLanguage = () => {
    switch (params.pageLanguage.toLowerCase()) {
      case "english":
        return getWordData(props.data.id)?.word;
      case "spanish":
        return getWordData(props.data.id)?.es;
      case "french":
        return getWordData(props.data.id)?.fr;
      default:
        return "";
    }
  };

  return (
    <div>
      <Card className="word-card mt-4 px-md-4 px-0">
        <Row noGutters className="mx-0">
          <Col sm="12" md="6" className="d-flex order-1 px-md-0 px-2 bordered">
            <Row noGutters className="w-100 mx-0 d-flex  p-2">
              <Col xs="6" className=" pr-">
                <div className="order- d-flex flex-column py-2">
                  {/* {script == "Native" && */}
                  {getWordData(props.data.id)?.wordNativeScript && (
                    <Label
                      style={{
                        fontFamily: language.font,
                        direction: language.direction,
                      }}
                    >
                      {getWordData(props.data.id).wordNativeScript}
                    </Label>
                  )}

                  {/* {script == "Nko" && */}
                  {getWordData(props.data.id)?.wordNkoScript && (
                    <Label style={{}}>
                      <div style={{ dir: "RTL" }}>
                        {getWordData(props.data.id).wordNkoScript}
                      </div>
                    </Label>
                  )}

                  {pageLanguage.toLowerCase() == "english" && (
                    <Label>{getWordData(props.data.id)?.word} </Label>
                  )}
                  {pageLanguage.toLowerCase() == "french" && (
                    <Label>{getWordData(props.data.id)?.fr} </Label>
                  )}
                  {pageLanguage.toLowerCase() == "spanish" && (
                    <Label> {getWordData(props.data.id)?.es}</Label>
                  )}

                  <Col xs="6" className="pl-0">
                    {pageLanguage.toLowerCase() == "english" &&
                      getWordData(props.data.id)?.englishDescription && (
                        <p style={{ fontSize: "15px" }}>
                          {getWordData(props.data.id).englishDescription}
                        </p>
                      )}
                    {pageLanguage.toLowerCase() == "french" &&
                      getWordData(props.data.id)?.frenchDescription && (
                        <p style={{ fontSize: "15px" }}>
                          {getWordData(props.data.id).frenchDescription}
                        </p>
                      )}
                    {pageLanguage.toLowerCase() == "spanish" &&
                      getWordData(props.data.id)?.spanishDescription && (
                        <p style={{ fontSize: "15px" }}>
                          {getWordData(props.data.id).spanishDescription}
                        </p>
                      )}
                  </Col>
                  {getWordData(props.data.id)?.dialect ? (
                    <div className="word-card__diaselect my-2">
                      {getWordData(props.data.id)?.dialect}
                    </div>
                  ) : (
                    <div className="word-card__diaselect my-2">
                      {"Standard"}
                    </div>
                  )}

                  {getWordData(props.data.id)?.partsOfSpeech && (
                    <p style={{ fontSize: "15px" }}>
                      {/* PARTS OF SPEECH:{" "} */}
                      {getWordData(props.data.id)?.partsOfSpeech}
                    </p>
                  )}

                  {getWordData(props.data.id)?.phoneticPronunciation && (
                    <Label>
                      {getWordData(props.data.id).phoneticPronunciation}
                    </Label>
                  )}

                  {getWordData(props.data.id)?.exampleSentences && (
                      <p>
                        <span className="mb-0">Example sentences:</span>
                      </p>
                    ) &&
                    getWordData(props.data.id)?.exampleSentences?.map(
                      (item) => {
                        if (item.exampleSentence != "") {
                          return <p>{item?.exampleSentence}</p>;
                        }
                      }
                    )}
                  {getWordData(props.data.id)?.notes[0] && (
                    <p>{getWordData(props.data.id).notes[0]}</p>
                  )}
                </div>
              </Col>

              {getWordData(props.data.id)?.image &&
                getWordData(props.data.id)?.image != "" && (
                  <Col xs="6" className="d-flex pr-">
                    <img
                      src={getWordData(props.data.id)?.image}
                      className="w-100 m-auto"
                    />
                  </Col>
                )}
            </Row>
          </Col>
          <Col xs="12" md="6" className=" order-2 d-flex py-2 px-md-0 px-2 ">
            <Row noGutters className="w-100 mx-0">
              <Col xs="9">
                <div className="order- d-flex flex-column py-2 px-4 mr-auto">
                  {getWordData(props.data.id)?.nativeDefintion && (
                    <Label
                      style={{
                        fontFamily: language.font,
                        direction: language.direction,
                      }}
                    >
                      {getWordData(props.data.id).nativeDefintion}
                    </Label>
                  )}

                  {getWordData(props.data.id)?.etymology && (
                    <Label style={{}}>
                      <div style={{ dir: "RTL" }}>
                        {getWordData(props.data.id).etymology}
                      </div>
                    </Label>
                  )}

                  {getWordData(props.data.id)
                    ?.nativeDescriptionNativeScript && (
                    <Label style={{}}>
                      <div style={{ dir: "RTL" }}>
                        {
                          getWordData(props.data.id)
                            .nativeDescriptionNativeScript
                        }
                      </div>
                    </Label>
                  )}

                  {getWordData(props.data.id)?.nativeDescriptionNkoScript && (
                    <Label style={{}}>
                      <div style={{ dir: "RTL" }}>
                        {getWordData(props.data.id).nativeDescriptionNkoScript}
                      </div>
                    </Label>
                  )}
                  {getWordData(props.data.id)?.nativeDescriptionLatinScript && (
                    <Label style={{}}>
                      <div style={{ dir: "RTL" }}>
                        {
                          getWordData(props.data.id)
                            .nativeDescriptionLatinScript
                        }
                      </div>
                    </Label>
                  )}
                </div>
              </Col>
              <Col xs="3" className="d-flex">
                <div className="ml-auto d-flex">
                  {/* <Button
													size='sm'
													className='word-card__edit'
													onClick={async () => {
														if (uid) {
															await setResultData(
																getWordData(
																	data.id
																)
															);
															setUpdateWord(
																prevState =>
																	!prevState
															);
														} else {
															toast.warning(
																'Login first to update word!'
															);
														}
													}}
												>
													Edit
												</Button> */}
                  <VisibilityIcon
                    className="cursor-pointer"
                    onClick={() => {
                      history.push({
                        pathname: `/Lang/${params.pageLanguage}/${
                          params.lang
                        }/${params.script}/word/${getWordByPageLanguage()}`,
                      });
                    }}
                  ></VisibilityIcon>
                  <EditIcon
                    className="edit-icon ml-2"
                    onClick={async () => {
                      if (uid) {
                        await setResultData(getWordData(props.data.id));
                        setUpdateWord((prevState) => !prevState);
                      } else {
                        toast.warning("Login first to update word!");
                      }
                    }}
                  ></EditIcon>
                  {/* <hr /> */}
                </div>
                <div className="">
                  <Label>
                    {getWordData(props.data.id)?.audio != "" && (
                      <>
                        {playing && audioId == props.data.id ? (
                          <div
                            className="word-card__audio cursor-pointer"
                            onClick={() => {
                              setaudioId(props.data.id);
                              setPlaying(false);
                            }}
                          >
                            <audio
                              id={props.data.id}
                              src={getWordData(props.data.id)?.audio}
                              // controls
                            />
                            {/* <VolumeUpIcon></VolumeUpIcon> */}
                            <i className="fa fa-pause" aria-hidden="true"></i>
                          </div>
                        ) : (
                          <div
                            className="word-card__audio cursor-pointer"
                            onClick={async () => {
                              setaudioId(props.data.id);
                              setPlaying(true);
                            }}
                          >
                            <VolumeUpIcon></VolumeUpIcon>
                            {/* <i
																			className='fa fa-play'
																			aria-hidden='true'
																		></i> */}
                            <audio
                              id={props.data.id}
                              src={getWordData(props.data.id)?.audio}
                              // controls
                            />
                          </div>
                        )}
                      </>
                    )}{" "}
                    {/* Language: */}
                    {/* {getWordData(data.id)?.language} */}
                  </Label>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Row> */}
        </Row>
      </Card>
      <UpdateWordModel
        prevData={resultData}
        updateWord={updateWord}
        setUpdateWord={setUpdateWord}
        handleClose={() => handleCloseU()}
        currentLanguage={lang}
      />
    </div>
  );
};

export default WordCard;
