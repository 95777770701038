import { FETCH_LANGUAGES } from "../types";
let lang = localStorage.getItem("lang");
let script = localStorage.getItem("script");
let initialState = {
  languages: "pending",
  pageLanguage: lang ? lang : "EN",
  script: script ? script : "Latin",
};

const languageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };
    case "SET_PAGE_LANGUAGE":
      localStorage.setItem("lang", payload);
      return {
        ...state,
        pageLanguage: payload,
      };
    case "SET_LANGUAGE_SCRIPT":
      localStorage.setItem("script", payload);
      return {
        ...state,
        script: payload,
      };
    default:
      return { ...state };
  }
};

export default languageReducer;
