import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { addWordAction } from "../../store/actions/wordActions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { checkScript } from "../checkScript";

const acceptedImageTypes = ["png", "jpg", "PNG", "JPG", "jpeg", "JPEG"];
const acceptedFileTypes = ["mp3", "MP3", "m4a"];

const AddWordModel = (props) => {
  const { wordLoading } = useSelector((state) => state.word);

  let { t } = useTranslation();
  const translation = t("translation", { returnObjects: true });

  const { uid } = useSelector((state) => state.authUser);
  let { languages, script, pageLanguage } = useSelector(
    (state) => state.language
  );

  const dispatch = useDispatch();
  const params = useParams();
  const [exampleSentences, setExampleSentences] = useState([
    { exampleSentence: "" },
  ]);
  const [notes, setNotes] = useState([]);
  const handleNotes = (e) => {
    let tempNotes = [...notes];
    tempNotes[0] = e.target.value;
    setNotes(tempNotes);
  };

  const checkImageFile = (imgFile) => {
    let imgChk = false;
    let imgCheck = imgFile.name.split(".");
    let ext = imgCheck[[imgCheck.length - 1]];
    if (!acceptedImageTypes.includes(ext)) {
      imgChk = true;
    }
    return imgChk;
  };

  const checkAudioFile = (audioFile) => {
    let audioChk = false;
    let audioCheck = audioFile.name.split(".");
    let ext = audioCheck[[audioCheck.length - 1]];
    if (!acceptedFileTypes.includes(ext)) {
      audioChk = true;
    }
    return audioChk;
  };

  const handleAddField = () => {
    setExampleSentences([...exampleSentences, { exampleSentence: "" }]);
  };
  const handleRemoveField = (ind) => {
    let temp = exampleSentences;
    temp.splice(ind, 1);
    setExampleSentences([...temp]);
  };

  const setValueExample = (ind, e) => {
    let temp = [...exampleSentences];
    temp[ind].exampleSentence = e.target.value;
    setExampleSentences(temp);
  };

  useEffect(() => {
    setWordData({ ...wordData, language: params.lang });
  }, [params]);

  const [wordData, setWordData] = useState({
    word: "",
    audio: "",
    image: "",
    partsOfSpeech: "",
    dialect: "",
    nativeDefintion: "",
    nativeScript: "",
    wordLatinScript: "",
    nativeDescriptionLatinScript: "",
    nativeDescriptionNkoScript: "",
    wordNkoScript: "",
    wordNativeScript: "",
    nativeDescriptionNativeScript: "",
    englishDescription: "",
    spanishDescription: "",
    frenchDescription: "",
    phoneticPronunciation: "",
    etymology: "",
    language: params.lang,
    es: "",
    fr: "",
  });
  const handleWordDataChange = (e) => {
    e.preventDefault();
    if (e.target.name == "image" || e.target.name == "audio") {
      setWordData({ ...wordData, [e.target.name]: e.target.files[0] });
    } else {
      setWordData({ ...wordData, [e.target.name]: e.target.value });
    }
  };

  const handleAddWord = (data) => {
    dispatch(
      addWordAction(data, () => {
        clearFields();
        props.setAddWord((prevState) => !prevState);
      })
    );
  };

  const handleAddWordData = (e) => {
    e.preventDefault();
    const AddWordFormData = {
      exampleSentences,
      notes,
      ...wordData,
      userId: uid,
    };

    if (AddWordFormData.image != "" || AddWordFormData.audio != "") {
      if (AddWordFormData.image != "") {
        if (!checkImageFile(AddWordFormData.image)) {
          if (AddWordFormData.audio != "") {
            if (!checkAudioFile(AddWordFormData.audio)) {
              handleAddWord(AddWordFormData);
            } else {
              toast.warning("Please select only mp3 file!");
            }
          } else {
            handleAddWord(AddWordFormData);
          }
        } else {
          toast.warning("Please select only PNG/JPG file!");
        }
      } else if (AddWordFormData.audio != "") {
        if (!checkAudioFile(AddWordFormData.audio)) {
          handleAddWord(AddWordFormData);
        } else {
          toast.warning("Please select only mp3 file!");
        }
      }
    } else {
      handleAddWord(AddWordFormData);
    }
  };

  const clearFields = () => {
    console.log("Clear Fields run...");
    setWordData({
      word: "",
      audio: "",
      image: "",
      partsOfSpeech: "",
      dialect: "",
      nativeDefintion: "",
      nativeScript: "",
      wordLatinScript: "",
      nativeDescriptionLatinScript: "",
      nativeDescriptionNkoScript: "",
      wordNkoScript: "",
      wordNativeScript: "",
      nativeDescriptionNativeScript: "",
      englishDescription: "",
      phoneticPronunciation: "",
      etymology: "",
      language: params.lang,
      es: "",
      fr: "",
    });
    setNotes([]);
    setExampleSentences([{ exampleSentence: "" }]);
  };
  let language;
  if (languages.length > 0) {
    language = languages.find((lang) => lang.id === params.lang);
    console.log(language);
  }
  console.log(
    "testingggggggggg",
    checkScript(translation, script)?.englishTranslation
  );
  const InputLanguageChange = () => {
    if (
      checkScript(translation, script)?.englishTranslation ==
      "English Translation"
    ) {
      return (
        <Input
          className="add-word-input"
          name="englishDescription"
          value={wordData.englishDescription}
          onChange={(e) => {
            handleWordDataChange(e);
          }}
        />
      );
    } else if (
      checkScript(translation, script)?.englishTranslation ==
      "Traducción Española"
    ) {
      return (
        <Input
          className="add-word-input"
          name="spanishDescription"
          value={wordData.spanishDescription}
          onChange={(e) => {
            handleWordDataChange(e);
          }}
        />
      );
    } else if (
      checkScript(translation, script)?.englishTranslation ==
      "Traduction française"
    ) {
      return (
        <Input
          className="add-word-input"
          name="frenchDescription"
          value={wordData.frenchDescription}
          onChange={(e) => {
            handleWordDataChange(e);
          }}
        />
      );
    }
  };

  console.log("page data...", pageLanguage);

  return (
    <div>
      <Modal
        isOpen={props.addWord}
        toggle={() => {
          clearFields();
          props.setAddWord((prevState) => !prevState);
        }}
        className=""
      >
        <ModalHeader
          className="text-site-dark border-0"
          toggle={() => {
            clearFields();
            props.setAddWord((prevState) => !prevState);
          }}
        >
          {`${checkScript(translation, script)?.add} ${
            checkScript(translation, script)?.word
          } (${params.lang})`}
        </ModalHeader>
        <ModalBody className="add-word-model">
          <Form
            onSubmit={(e) => {
              handleAddWordData(e);
            }}
          >
            <Row>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {language.name} {checkScript(translation, script)?.word}{" "}
                  (Latin ${checkScript(translation, script)?.scriptTrans})
                </Label>
                <Input
                  className="add-word-input"
                  name={`${
                    pageLanguage.toLowerCase() == "en"
                      ? "word"
                      : pageLanguage.toLowerCase()
                  }`}
                  required
                  value={
                    pageLanguage.toLowerCase() == "es"
                      ? wordData.es
                      : pageLanguage.toLowerCase() == "fr"
                      ? wordData.fr
                      : wordData.word
                  }
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              {/* <Col md='6' className='my-2'>
								<Label className='mb-0 my-auto'>tifinagh</Label>
								<Input
									className='add-word-input'
									name='tifinagh'
									value={wordData.tifinagh}
									onChange={e => {
										handleWordDataChange(e);
									}}
								/>
							</Col> */}
              {/* <Col md='6' className='my-2 '>
								<Label className='mb-0 my-auto'>Phonetic</Label>
								<Input
									className='add-word-input'
									name='phonetic'
									value={wordData.phonetic}
									onChange={e => {
										handleWordDataChange(e);
									}}
								/>
							</Col> */}
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto" for="exampleSelect">
                  {checkScript(translation, script)?.partsOfSpeech}
                </Label>
                <Input
                  id="exampleSelect"
                  name="partsOfSpeech"
                  type="select"
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                  value={wordData.partsOfSpeech}
                >
                  <option value="" default>
                    Select
                  </option>
                  <option value="Noun">Noun</option>
                  <option value="Pronoun">Pronoun</option>
                  <option value="Verb">Verb</option>
                  <option value="Adjective">Adjective</option>
                  <option value="Adverb">Adverb</option>
                  <option value="Preposition">Preposition</option>
                  <option value="Conjunction">Conjunction</option>
                  <option value="Interjection">Interjection</option>
                </Input>
              </Col>
              <Col md="6" className="my-2">
                <Label>{checkScript(translation, script)?.image}</Label>
                <Input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label>{checkScript(translation, script)?.audio}</Label>
                <Input
                  type="file"
                  accept="audio"
                  name="audio"
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto" for="dialect">
                  {checkScript(translation, script)?.dialect}
                </Label>
                <Input
                  id="dialect"
                  name="dialect"
                  type="select"
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                  value={wordData.dialect}
                >
                  <option value="" default>
                    Select
                  </option>
                  {language?.dialects?.length > 0 &&
                    language?.dialects.map((dialect) => (
                      <option value={dialect}>{dialect}</option>
                    ))}
                  {/* <option value='Dialect1'>Dialect 1</option> */}
                </Input>
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {checkScript(translation, script)?.definition}{" "}
                  {checkScript(translation, script)?.in} {language.name} (Latin{" "}
                  {checkScript(translation, script)?.scriptTrans}){" "}
                </Label>
                <Input
                  className="add-word-input"
                  name="nativeDefintion"
                  value={wordData.nativeDefintion}
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {language.name} {checkScript(translation, script)?.word} (Nko{" "}
                  {checkScript(translation, script)?.scriptTrans}){" "}
                </Label>
                <Input
                  style={{ direction: "RTL" }}
                  className="add-word-input"
                  name="wordNkoScript"
                  value={wordData.wordNkoScript}
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {language.name} {checkScript(translation, script)?.definition}{" "}
                  {checkScript(translation, script)?.in} Nko{" "}
                  {checkScript(translation, script)?.scriptTrans}{" "}
                </Label>
                <Input
                  style={{ direction: "RTL" }}
                  className="add-word-input"
                  name="nativeDescriptionNkoScript"
                  value={wordData.nativeDescriptionNkoScript}
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              {language.hasNativeScript && (
                <Col md="6" className="my-2">
                  <Label className="mb-0 my-auto">
                    {language.name} {checkScript(translation, script)?.word}{" "}
                    {checkScript(translation, script)?.in}{" "}
                    {language.nativeScriptName}{" "}
                    {checkScript(translation, script)?.scriptTrans}{" "}
                  </Label>
                  <Input
                    style={{
                      fontFamily: language.font,
                      direction: language.direction,
                    }}
                    className="add-word-input"
                    name="wordNativeScript"
                    value={wordData.wordNativeScript}
                    onChange={(e) => {
                      handleWordDataChange(e);
                    }}
                  />
                </Col>
              )}
              {language.hasNativeScript && (
                <Col md="6" className="my-2">
                  <Label className="mb-0 my-auto">
                    {language.name}{" "}
                    {checkScript(translation, script)?.definition}{" "}
                    {checkScript(translation, script)?.in}{" "}
                    {language.nativeScriptName}{" "}
                    {checkScript(translation, script)?.scriptTrans}{" "}
                  </Label>
                  <Input
                    style={{
                      fontFamily: language.font,
                      direction: language.direction,
                    }}
                    className="add-word-input"
                    name="nativeDescriptionNativeScript"
                    value={wordData.nativeDescriptionNativeScript}
                    onChange={(e) => {
                      handleWordDataChange(e);
                    }}
                  />
                </Col>
              )}
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {checkScript(translation, script)?.englishTranslation}{" "}
                </Label>
                {InputLanguageChange()}
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {checkScript(translation, script)?.phoneticPronunciation}
                </Label>
                <Input
                  className="add-word-input"
                  name="phoneticPronunciation"
                  value={wordData.phoneticPronunciation}
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              <Col md="6" className="my-2">
                <Label className="mb-0 my-auto">
                  {checkScript(translation, script)?.etymology}
                </Label>
                <Input
                  className="add-word-input"
                  name="etymology"
                  value={wordData.etymology}
                  onChange={(e) => {
                    handleWordDataChange(e);
                  }}
                />
              </Col>
              <div className="addword-exp-sntnce my-2 ml-3">
                <Label>
                  {checkScript(translation, script)?.exampleSentences}
                </Label>

                {exampleSentences.map((obj, ind) => {
                  return (
                    <Col className=" d-flex my-1 pl-0" md="12">
                      <Input
                        className=" w-100 mr-2"
                        name="exampleSentence"
                        placeholder="exampleSentence"
                        value={obj.exampleSentence}
                        onChange={(e) => {
                          setValueExample(ind, e);
                        }}
                      />

                      {exampleSentences.length > 1 &&
                        ind != exampleSentences.length - 1 && (
                          <Button
                            className="addword-icon-minus"
                            onClick={() => {
                              handleRemoveField(ind);
                            }}
                          >
                            <i
                              className="fa fa-minus-circle"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        )}
                      {exampleSentences.length - 1 == ind && (
                        <>
                          <Button
                            className="addword-icon"
                            onClick={handleAddField}
                          >
                            {" "}
                            <span>
                              <i class="fas fa-plus-circle"></i>
                            </span>{" "}
                          </Button>
                        </>
                      )}
                    </Col>
                  );
                })}
              </div>

              <Col md="12" className="my-2">
                <Label>{checkScript(translation, script)?.notes}</Label>
                <Input
                  type="textarea"
                  name="notes"
                  value={notes[0]}
                  onChange={(e) => {
                    handleNotes(e);
                  }}
                />
              </Col>
            </Row>
            <div className="text-right my-2">
              <Button
                type="submit"
                className="px-5 review-btn"
                disabled={wordLoading}
              >
                {wordLoading ? (
                  <Spinner size="sm" />
                ) : (
                  checkScript(translation, script)?.review
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddWordModel;
