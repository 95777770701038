import {
  SET_ADD_WORD_LOADING,
  UPDATE_WORD,
  FETCH_WORD_VERSIONS,
  SET_VERSIONS_LOADING,
  SET_STATUS_LOADING,
  GET_SINGLE_WORD,
  GET_WORD_LOADING,
  GET_SINGLE_WORD_BY_LANGUAGE,
} from "../types";

const initialState = {
  wordLoading: false,
  statusLoading: false,
  word: {},
  versions: [],
  getWordLoading: false,
};

export default function wordReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WORD:
      return {
        // ...state,
        // wordLoading: action.payload,
      };
    case SET_ADD_WORD_LOADING:
      return {
        ...state,
        wordLoading: action.payload,
      };
    case FETCH_WORD_VERSIONS:
      return {
        ...state,
        versions: action.payload,
      };
    case SET_VERSIONS_LOADING:
      return {
        ...state,
        versionsLoading: action.payload,
      };
    case SET_STATUS_LOADING:
      return {
        ...state,
        statusLoading: action.payload,
      };
    // case GET_SINGLE_WORD:
    //   return {
    //     ...state,
    //     word: action.payload,
    //   };
    case GET_SINGLE_WORD_BY_LANGUAGE:
      return {
        ...state,
        word: action.payload,
      };
    case GET_WORD_LOADING:
      return {
        ...state,
        getWordLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
