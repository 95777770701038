import firebase from "../../config/firebase-config";
import { SET_FETCHED_WORDS } from "../types";

export const getWordAction = (lang) => async (dispatch) => {
   dispatch({
		type: SET_FETCHED_WORDS,
		payload: 'loading',
   });
  firebase
    .firestore()
    .collection("Words")
    .where("language", "==", lang)
    .onSnapshot((query) => {
      let fetchedWord = [];
      query.forEach((item) => {
        fetchedWord.push({ id: item.id, ...item.data() });
      });
      dispatch({
        type: SET_FETCHED_WORDS,
        payload: fetchedWord,
      });
      console.log(fetchedWord);
    });
};
